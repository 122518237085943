import React from "react";
import "./Contant.css";
import { useState, useEffect } from "react";
import AWS from "aws-sdk";
import "../components/Stay.css";
import HoverVideoPlayer from "react-hover-video-player";
import { FourSquare } from "react-loading-indicators";
import { OrbitProgress } from "react-loading-indicators";

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});
function Contant() {
  const [data, setData] = useState([]);
  const [dataLakes, setDataLakes] = useState([]);
  const [dataGardens, setDataGardens] = useState([]);
  // const [dataParks, setDataParks] = useState([]);
  const [dataViewpoints, setDataViewpoints] = useState([]);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ooty_highlights",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setData(result.Items);
      }
    });
  }, []);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-lakes",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setDataLakes(result.Items);
      }
    });
  }, []);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-gardens",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setDataGardens(result.Items);
      }
    });
  }, []);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-viewpoints",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setDataViewpoints(result.Items);
      }
    });
  }, []);

  const shuffleHotel = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const shuffledData = shuffleHotel([...data]);

  return (
    <>
      <h1 className="Contant_Titel">Top 10 Higlights of Ooty</h1>

      <div className="Contant_Highlight">
        {shuffledData.length > 0 ? (
          shuffledData.map(({ name, imgurl, video }, index) => (
            <div key={index}>
              <div className="Contant_Higlights">
                <HoverVideoPlayer
                  videoSrc={video}
                  crossOrigin="anonymous"
                  className="custom-video-player"
                  pausedOverlay={
                    <img
                      src={imgurl}
                      alt=""
                      className="custome_image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  }
                  loadingOverlay={
                    <div className="Contant_Highlight_Loading">
                      <OrbitProgress
                        color="#32cd32"
                        size="small"
                        text=""
                        textColor=""
                      />
                    </div>
                  }
                />
              </div>

              <h3 className="Contant_item_title">{name}</h3>
            </div>
          ))
        ) : (
          <FourSquare color="#32cd32" size="medium" text="" textColor="" />
        )}
      </div>

      <h1 className="Contant_Titel">Lakes in Ooty</h1>

      <div className="Contant_Highlight">
        {dataLakes.length > 0 ? (
          dataLakes.map(({ name, imgurl, video }, index) => (
            <div key={index}>
              <div className="Contant_Higlights">
                <HoverVideoPlayer
                  videoSrc={video}
                  crossOrigin="anonymous"
                  className="custom-video-player"
                  pausedOverlay={
                    <img
                      src={imgurl}
                      alt=""
                      className="custome_image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  }
                  loadingOverlay={
                    <div className="Contant_Highlight_Loading">
                      <OrbitProgress
                        color="#32cd32"
                        size="small"
                        text=""
                        textColor=""
                      />
                    </div>
                  }
                />
              </div>

              <h3 className="Contant_item_title">{name}</h3>
            </div>
          ))
        ) : (
          <FourSquare color="#32cd32" size="medium" text="" textColor="" />
        )}
      </div>

      <h1 className="Contant_Titel">Gardens & Parks in Ooty</h1>

      <div className="Contant_Highlight">
        {dataGardens.length > 0 ? (
          dataGardens.map(({ name, imgurl, video }, index) => (
            <div key={index}>
              <div className="Contant_Higlights">
                <HoverVideoPlayer
                  videoSrc={video}
                  crossOrigin="anonymous"
                  className="custom-video-player"
                  pausedOverlay={
                    <img
                      src={imgurl}
                      alt=""
                      className="custome_image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  }
                  loadingOverlay={
                    <div className="Contant_Highlight_Loading">
                      <OrbitProgress
                        color="#32cd32"
                        size="small"
                        text=""
                        textColor=""
                      />
                    </div>
                  }
                />
              </div>

              <h3 className="Contant_item_title">{name}</h3>
            </div>
          ))
        ) : (
          <FourSquare color="#32cd32" size="medium" text="" textColor="" />
        )}
      </div>
      {dataViewpoints.length > 0 ? (
        <>
          <h1 className="Contant_Titel">View points in Ooty</h1>
          <div className="Contant_Highlight">
            {dataViewpoints.length > 0 ? (
              dataViewpoints.map(({ name, imgurl, video }, index) => (
                <div key={index}>
                  <div className="Contant_Higlights">
                    <HoverVideoPlayer
                      videoSrc={video}
                      crossOrigin="anonymous"
                      className="custom-video-player"
                      pausedOverlay={
                        <img
                          src={imgurl}
                          alt=""
                          className="custome_image"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      }
                      loadingOverlay={
                        <div className="Contant_Highlight_Loading">
                          <OrbitProgress
                            color="#32cd32"
                            size="small"
                            text=""
                            textColor=""
                          />
                        </div>
                      }
                    />
                  </div>

                  <h3 className="Contant_item_title">{name}</h3>
                </div>
              ))
            ) : (
              <FourSquare color="#32cd32" size="medium" text="" textColor="" />
            )}
          </div>
        </>
      ) : null}
    </>
  );
}

export default Contant;
