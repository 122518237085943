import Navbar from "../../page/Navbar";
import "./AdminUser.css";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { CiSearch } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import * as XLSX from "xlsx";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { BiBlock } from "react-icons/bi";
export default function AdminUser() {
  const { user } = useAuthenticator((context) => [context.user]);
  const [adminName, setAdminName] = useState("");
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "amdmin-users",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        const adminUseris = result.Items.filter(
          (item) => item.email === user?.signInDetails.loginId
        );
        setAdminName(adminUseris);
      }
    });
  }, [user]);

  useEffect(() => {}, []);
  const [hotelData, setHotelData] = useState([]);
  const [resData, setResData] = useState([]);
  const [travelData, setTravelData] = useState([]);
  const [payUserData, setPayUserData] = useState([]);
  const [subUserData, setSubUserData] = useState([]);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-hotel",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setHotelData(result.Items);
      }
    });
  }, []);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-restaurant",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB");
      } else {
        setResData(result.Items);
      }
    });
  }, []);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-travel",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setTravelData(result.Items);
      }
    });
  }, []);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Subscription-User-list",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setSubUserData(result.Items);
      }
    });
  }, []);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-tour-payments",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setPayUserData(result.Items);
      }
    });
  }, []);

  const [searchItems, setSearchItems] = useState([]);
  const [allData, setAllData] = useState(null);

  const handleSearch = (event) => {
    setSearchItems(event.target.value);
    const inupuisEmpty = event.target.value;
    if (inupuisEmpty.toString() === "") setAllData(null);
  };

  const handleFilter = (event) => {
    event.preventDefault();

    // Hotel data
    const filteredHotels = hotelData.filter(
      (item) => item?.username === searchItems.toLowerCase()
    );

    // Restaurant data
    const filteredRes = resData.filter(
      (item) => item?.username === searchItems.toLowerCase()
    );

    // Travel data
    const filteredTravel = travelData.filter(
      (item) => item?.username === searchItems.toLowerCase()
    );

    // pay

    const filteredPay = payUserData.filter(
      (item) => item?.username === searchItems.toLowerCase()
    );

    // Sub User

    const filteredSub = subUserData.filter(
      (item) => item?.email === searchItems.toLowerCase()
    );

    const combinedData = [
      ...filteredHotels,
      ...filteredRes,
      ...filteredTravel,
      ...filteredPay,
      ...filteredSub,
    ];
    setAllData(combinedData);
  };

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(allData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "User-Data");
    XLSX.writeFile(wb, `${searchItems}(data).xlsx`);
  };
  // Edit data
  const [editActive, setEditActive] = useState(false);

  const handleEditData = () => {
    window.alert("edit activated");
  };

  const [formData, setFormData] = useState([]);

  const handleChange = (e, field) => {
    const newFormData = [...formData];
    newFormData[field] = e.target.value;
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Call the edit function here
    handleEditData(formData);
  };
  return (
    <>
      <Navbar />
      {adminName?.length > 0 ? (
        <>
          <div className="AdminUser_Contant">
            <h1 className="AdminUser_Contant_Titel">
              Customer Management Center
            </h1>
            {editActive ? (
              <div
                className={
                  editActive ? "Admin_Edit_Form" : "Admin_Edit_Form_None"
                }
              >
                <h3 className="Admin_Edit_Title">Id: {formData[0]} <br />Name: {formData[1]}</h3>
                <form
                  onSubmit={handleSubmit}
                  className="Admin_Edit_Form_Contant"
                >
                  <div>
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      onChange={(e) => handleChange(e, "name")}
                    />
                    <label htmlFor="">Cell</label>
                    <input
                      type="number"
                      onChange={(e) => handleChange(e, "cell")}
                    />
                    <label htmlFor="">Rate</label>
                    <input
                      type="number"
                      onChange={(e) => handleChange(e, "rate")}
                    />
                    <label htmlFor="">Package</label>
                    <input
                      type="number"
                      onChange={(e) => handleChange(e, "pkg")}
                    />
                    <label htmlFor="">About</label>
                    <textarea
                      type="text"
                      onChange={(e) => handleChange(e, "about")}
                    />
                    <label htmlFor="">Location</label>
                    <input
                      type="text"
                      onChange={(e) => handleChange(e, "area")}
                    />
                  </div>

                  <button type="submit">Update</button>
                  <button type="button" onClick={() => setEditActive(false)}>
                    Cancel
                  </button>
                </form>
              </div>
            ) : null}

            <div>
              <form action="submit" onSubmit={handleFilter}>
                <input
                  type="email"
                  onChange={handleSearch}
                  className="Admin_Form_Email"
                  placeholder="example@gmail.com"
                  required
                />
                <button type="submit" className="Admin_Form_Btn">
                  <FaRegUser />
                  <CiSearch />
                </button>
              </form>
              {allData?.length > 0 && <h3>Property Details</h3>}
              {allData?.length > 0 ? (
                <table className="Data_Table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Username</th>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Cell</th>
                      <th>Rate</th>
                      <th>Package</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allData
                      .filter((item) => item.name > "")
                      .map(({ id, name, cell, rate, pkg, username }, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{username}</td>
                          <td>{id}</td> <td>{name}</td> <td>{cell}</td>
                          <td>{rate}</td> <td>{pkg}</td>
                          <td>
                            <FaEdit
                              className="Admin_Btn"
                              onClick={() => {
                                setFormData([id, name, cell, rate, username]);
                                console.log(formData);
                                setEditActive(true);
                              }}
                            />
                          </td>
                          <td>
                            <MdDeleteOutline className="Admin_Btn" />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <>
                  {allData?.length === 0 && (
                    <p className="Filter_User_Empty">
                      Oops! the email address you entered does not match any
                      account in the system
                    </p>
                  )}
                </>
              )}
              {allData?.length > 0 && <h3>Payment Details</h3>}
              {allData?.length > 0 ? (
                <table className="Data_Table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Username</th>
                      <th>Amount</th>
                      <th>Payment Id</th>
                      <th>Date</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Package Name</th>
                      <th>Status</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allData
                      .filter((item) => item.payment_id > "")
                      .map(
                        (
                          {
                            username,
                            amount,
                            payment_id,
                            date,
                            start_date,
                            end_date,
                            package_name,
                            status,
                          },
                          index
                        ) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{username}</td>
                            <td>{amount}</td>
                            <td>{payment_id}</td>
                            <td>
                              {date
                                ?.slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("-")}
                            </td>
                            <td>
                              {start_date
                                ?.slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("-")}
                            </td>
                            <td>
                              {end_date
                                ?.slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("-")}
                            </td>
                            <td>{package_name}</td>
                            <td>{status}</td>
                            <td>
                              <FaEdit
                                className="Admin_Btn"
                                onClick={() => {
                                  setFormData([
                                    username,
                                    payment_id,
                                    start_date,
                                    end_date,
                                    package_name,
                                    status,
                                  ]);
                                  setEditActive(true);
                                }}
                              />
                            </td>
                            <td>
                              <MdDeleteOutline className="Admin_Btn" />
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              ) : (
                <>
                  {allData?.length === 0 && (
                    <p className="Filter_User_Empty">
                      Oops! the email address you entered does not match any
                      account in the system
                    </p>
                  )}
                </>
              )}
              {allData?.length > 0 && (
                <button
                  onClick={handleDownload}
                  className="Admin_Form_Excel_Btn"
                >
                  Download as Excel
                </button>
              )}
            </div>
            <div className="Admin_Data_Management"></div>
          </div>
        </>
      ) : (
        <>
          <div className="Unauthuser_Contant">
            <p>
              <BiBlock className="Unauthuser_Contant_Icon" />
            </p>
            <h2 className="Unauthuser_Contant_Msg">
              Permission Denied: You do not have the necessary permissions to
              view this page.
            </h2>
          </div>
        </>
      )}
    </>
  );
}
