import React from "react";
import "./About.css";
import image1 from "../assets/images/kavi.png";
import image2 from "../assets/images/dinesh.png";
import image3 from "../assets/images/vinoth.png";
import image4 from "../assets/images/ruban.png";
import image5 from "../assets/images/guru.png";
import image6 from "../assets/images/Dhivya.jpg";
import image7 from "../assets/images/sasikala.jpg";
import Navbar from "../page/Navbar";
import Footer from "../page/Footer";
import { useEffect } from "react";
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="About_contant">
        <div className="About_ootygo">
          <h1>About OotyGo</h1>
          <p>
            Ootygo Technologies, headquartered in the picturesque town of Ooty,
            excels in Website Development, E-commerce Support, Booking
            Management, Digital Marketing, Digital Transformation, Cloud
            Engineering, IT Training, Artificial Intelligence, and Social Media
            Management. We are dedicated to offering comprehensive support for
            tour and travel services, ensuring tourists and travelers receive
            unparalleled assistance and the finest experiences.
          </p>
        </div>
        <div className="About_OurTeam">
          <h2>Meet our team</h2>
          <div className="About_team_members">
            <div className="About_Team_member">
              <img src={image1} className="About_img" alt="Team" />
              <h2 className="About_team_title">Kaviyarasan</h2>
              <p>Captain of the Ship</p>
            </div>
            <div className="About_Team_member">
              <img src={image2} className="About_img" alt="Team" />
              <h2 className="About_team_title">Dinesh</h2>
              <p>The Tech Wizard</p>
            </div>
            <div className="About_Team_member">
              <img src={image4} className="About_img" alt="Team" />
              <h2 className="About_team_title">Ruban</h2>
              <p>Number Ninja</p>
            </div>
            <div className="About_Team_member">
              <img src={image6} className="About_img" alt="Team" />
              <h2 className="About_team_title">Dhivya</h2>
              <p>Innovation Insider</p>
            </div>
            <div className="About_Team_member">
              <img src={image7} className="About_img" alt="Team" />
              <h2 className="About_team_title">Sasikala</h2>
              <p>Customer Concierge</p>
            </div>
            <div className="About_Team_member">
              <img src={image3} className="About_img" alt="Team" />
              <h2 className="About_team_title">Vinoth</h2>
              <p>Productivity Pro</p>
            </div>

            <div className="About_Team_member">
              <img src={image5} className="About_img" alt="Team" />
              <h2 className="About_team_title">Guru</h2>
              <p>Visual Storyteller</p>
            </div>
          </div>
        </div>
        <h2>Careers in OotyGo</h2>
        <div className="About_career">
          <div className="About_career_Items">
            <h3>Investment Opportunity in OotyGo Technologies</h3>
            <p>
              We are excited to invite investors to join us in our journey to
              revolutionize the travel and hospitality industry with our
              innovative solutions.
            </p>
          </div>
          <div className="About_career_Items">
            <h3>MERN Stack Developer Fresher</h3>
            <p>
              We're looking for a highly motivated and detail-oriented MERN
              Stack Developer Fresher to contribute to the development of our
              web applications. As a MERN Stack Developer Fresher, you will be
              responsible for designing, developing, and testing our web
              applications using MongoDB, Express.js, React.js, and Node.js.
            </p>
          </div>
          <div className="About_career_Items">
            <h3>AWS Architect Fresher</h3>
            <p>
              We're looking for a motivated and detail-oriented AWS Architect
              Fresher to assist in designing, building, and maintaining our
              cloud infrastructure on Amazon Web Services (AWS). As an AWS
              Architect Fresher, you will be responsible for learning and
              implementing cloud-based solutions.
            </p>
          </div>
          <div className="About_career_Items">
            <h3>Business Development Executive</h3>
            <p>
              We're looking for a talented Business Development Executive to
              identify and capitalize on new business opportunities, build
              relationships with clients, and drive revenue growth.
            </p>
          </div>
          <div className="About_career_Items">
            <h3>CRM Executive</h3>
            <p>
              We're looking for a talented CRM Executive to manage and maintain
              our customer relationships, ensure data accuracy, and drive
              business growth.
            </p>
          </div>
          <div className="About_career_Items">
            <h3>Data Analyst</h3>
            <p>
              We're looking for a talented Data Analyst to collect, organize,
              and analyze data to help us make informed business decisions. As a
              Data Analyst, you will be responsible for developing and
              maintaining databases, data visualizations, and reports.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
