import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AWS from "aws-sdk";

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const HotelList = () => {
  const [hotels, setHotels] = useState([]);

  const fetchHotels = async () => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-hotel",
    };

    try {
      const result = await dynamodb.scan(params).promise();
      return result.Items;
    } catch (err) {
      console.error("Error fetching data from DynamoDB:", err);
      return [];
    }
  };

  useEffect(() => {
    const getHotels = async () => {
      const hotelData = await fetchHotels();
      setHotels(hotelData);
    };

    getHotels();
  }, []);

  return (
    <div>
      <h1>Hotel List</h1>
      <ul>
        {hotels.map((hotel) => (
          <li key={hotel.id}>
            <Link to={`/hotel/${hotel.id}`}>{hotel.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HotelList;
