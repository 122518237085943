import React from "react";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { useAuthenticator } from "@aws-amplify/ui-react";
import "./Mybookings.css";
import { MdDeleteForever } from "react-icons/md";
import { DynamoDBClient, DeleteItemCommand } from "@aws-sdk/client-dynamodb";
import { useNavigate } from "react-router-dom";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import DatePicker from "react-multi-date-picker";
import { CiCalendarDate } from "react-icons/ci";
import { MdOutlineCancel } from "react-icons/md";
import { LuView } from "react-icons/lu";
import { MdMenuBook } from "react-icons/md";
import { TiInfoOutline } from "react-icons/ti";
import { FaRegShareSquare } from "react-icons/fa";
function Mybookings() {
  const { user } = useAuthenticator((context) => [context.user]);
  const [data, setData] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [Hotel, setHote] = useState([]);
  const [Hotelmenu, setHotelMenu] = useState(false);
  const [Business, setBusiness] = useState([]);
  // Initialize AWS SDK
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  // Get Hotels
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-hotel",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setData(result.Items);
      }
    });
  }, []);

  // Get Hotels
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-restaurant",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setHote(
          result.Items.filter(
            (item) => item.username === user?.signInDetails.loginId
          )
        );
      }
    });
  }, [user]);

  // const [size, setSize] = useState(0);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-travel",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setVehicle(result.Items);
      }
    });
  }, []);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-business",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setBusiness(result.Items);
      }
    });
  }, []);

  const loginUserName = user.signInDetails.loginId;

  const loginUserHotel = data.filter((item) => item.username === loginUserName);
  const loginUserVehicle = vehicle.filter(
    (item) => item.username === loginUserName
  );
  const loginUserRes = Hotel.filter((item) => item.username === loginUserName);
  const loginUserBus = Business.filter(
    (item) => item.username === loginUserName
  );

  // delet vehicle
  const client = new DynamoDBClient({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
  });
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/SignUp");
  };
  const deleteItem = async (id, name) => {
    const userConfirmed = window.confirm(`You are deleting ${name}`);
    if (!userConfirmed) {
      return;
    }
    const params = {
      TableName: "Ootygo-hotel",
      Key: {
        id: { N: id.toString() },
        name: { S: name },
      },
    };

    try {
      const command = new DeleteItemCommand(params);
      await client.send(command);
      window.alert("Asset Deleted");
      handleClick();
    } catch (error) {
      console.error("Error deleting item:", error);
      window.alert("Error In Deleteing");
    }
  };

  const deleteRes = async (id, name) => {
    const userConfirmed = window.confirm(`You are deleting ${name}`);
    if (!userConfirmed) {
      return;
    }
    const params = {
      TableName: "ootygo-restaurant",
      Key: {
        id: { N: id },
        name: { S: name },
      },
    };

    try {
      const command = new DeleteItemCommand(params);
      await client.send(command);
      window.alert("Asset Deleted");
      handleClick();
    } catch (error) {
      console.error("Error deleting item:", error);
      window.alert("Error In Deleteing");
    }
  };

  const deleteItemvh = async (id, name, car) => {
    const userConfirmed = window.confirm(`You are deleting ${car}`);
    if (!userConfirmed) {
      return;
    }
    const params = {
      TableName: "Ootygo-travel",
      Key: {
        id: { S: id.toString() },
        name: { S: name },
      },
    };

    try {
      const command = new DeleteItemCommand(params);
      await client.send(command);
      window.alert("Asset Deleted");
      handleClick();
    } catch (error) {
      console.error("Error deleting item:", error);
      window.alert("Error In Deleteing");
    }
  };

  const deleteIBusiness = async (id, name) => {
    const userConfirmed = window.confirm(`You are deleting ${name}`);
    if (!userConfirmed) {
      return;
    }
    const params = {
      TableName: "ootygo-business",
      Key: {
        id: { N: id.toString() },
        name: { S: name },
      },
    };

    try {
      const command = new DeleteItemCommand(params);
      await client.send(command);
      window.alert("Asset Deleted");
      handleClick();
    } catch (error) {
      console.error("Error deleting item:", error);
      window.alert("Error In Deleteing");
    }
  };

  // Post Date Available
  const dynamoDB = new AWS.DynamoDB.DocumentClient();
  const [selectedDate, setSelectedDate] = useState([]);
  const [ChangeDate, setChangDate] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [hotelname, setHotelName] = useState("");
  const [ResId, setResId] = useState(null);
  const [ResName, setResName] = useState("");
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = async () => {
    if (selectedDate.length > 0) {
      const newFormattedDate = selectedDate
        .map((date) => date.format("YYYY-MM-DD"))
        .join(", ");

      const paramsGet = {
        TableName: "ootygo-hotelavailability",
        Key: {
          hotelID: Number(hotelId),
          roomID: 0,
        },
      };

      try {
        // Retrieve the current item
        const result = await dynamoDB.get(paramsGet).promise();
        const currentFormattedDate = result.Item
          ? result.Item.availableDate
          : "";

        // Combine the current dates with the new dates
        const updatedFormattedDate = currentFormattedDate
          ? `${currentFormattedDate}, ${newFormattedDate}`
          : newFormattedDate;

        const paramsPut = {
          TableName: "ootygo-hotelavailability",
          Item: {
            hotelID: Number(hotelId),
            roomID: 0,
            name: hotelname,
            availableDate: updatedFormattedDate,
          },
        };

        // Update the item with the combined dates
        await dynamoDB.put(paramsPut).promise();
        setChangDate(false);
        setSelectedDate([]);
        alert("Date added successfully!");
      } catch (error) {
        console.error("Error adding date:", error);
        alert("Failed to add date.");
      }
    } else {
      alert("Please select a date.");
    }
  };

  const handleDelete = async () => {
    if (selectedDate.length > 0) {
      const formattedDatesToDelete = selectedDate.map((date) =>
        date.format("YYYY-MM-DD")
      );

      const paramsGet = {
        TableName: "ootygo-hotelavailability",
        Key: {
          hotelID: Number(hotelId),
          roomID: 0,
        },
      };

      try {
        // Retrieve the current item
        const result = await dynamoDB.get(paramsGet).promise();
        let currentFormattedDate = result.Item ? result.Item.availableDate : "";

        // Remove the dates to be deleted from the current dates
        if (currentFormattedDate) {
          const currentDatesArray = currentFormattedDate.split(", ");
          const updatedDatesArray = currentDatesArray.filter(
            (date) => !formattedDatesToDelete.includes(date)
          );
          currentFormattedDate = updatedDatesArray.join(", ");
        }

        const paramsUpdate = {
          TableName: "ootygo-hotelavailability",
          Key: {
            hotelID: Number(hotelId),
            roomID: 0,
          },
          UpdateExpression: "SET availableDate = :updatedFormattedDate",
          ExpressionAttributeValues: {
            ":updatedFormattedDate": currentFormattedDate,
          },
        };

        // Update the item with the modified dates
        await dynamoDB.update(paramsUpdate).promise();
        setChangDate(false);
        setSelectedDate([]);
        alert("Date(s) removed successfully!");
      } catch (error) {
        console.error(`Error removing date(s):`, error);
        alert("Failed to remove date(s).");
      }
    } else {
      alert("Please select a date.");
    }
  };

  // Handle Menu Update
  const [menuItems, setMenuItems] = useState([
    { dishName: "", price: "", foodImage: null },
  ]);
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...menuItems];
    updatedItems[index][name] = value;
    setMenuItems(updatedItems);
  };
  const handleFileChange = (index, e) => {
    const { files } = e.target;
    const updatedItems = [...menuItems];
    updatedItems[index].foodImage = files[0];
    setMenuItems(updatedItems);
  };
  const uploadFile = async (file) => {
    const s3 = new AWS.S3();
    const folderName = ResName;
    console.log("Folder Name :", folderName);
    const params = {
      Bucket: "ootygo-res-food-img",
      Key: `${folderName}/${file.name}`,
      Body: file,
    };
    const data = await s3.upload(params).promise();
    return data.Location;
  };
  const addMenuItem = () => {
    setMenuItems([...menuItems, { dishName: "", price: "", foodImage: null }]);
  };
  const removeMenuItem = () => {
    setMenuItems([{ dishName: "", price: "", foodImage: null }]);
  };
  const handleMenu = async (e) => {
    e.preventDefault();
    try {
      const dynamodbRes = new AWS.DynamoDB.DocumentClient();
      const newIdis = new Date().getTime(); // Single unique ID for all items
      const menuItemsObject = {};
      for (let index = 0; index < menuItems.length; index++) {
        const item = menuItems[index];
        const imgurl = await uploadFile(item.foodImage);
        const itemId = `${newIdis}-${index}`; // Unique key for each item
        menuItemsObject[itemId] = {
          dishName: item.dishName,
          price: Number(item.price),
          foodImage: imgurl,
        };
      }
      const params = {
        TableName: "ootygo-restaurant-menu",
        Item: { id: Number(ResId), name: ResName, menu: menuItemsObject },
      };
      await dynamodbRes.put(params).promise();
      alert("Data saved successfully!");
    } catch (error) {
      console.error("Error adding menu items:", error);
      alert("Error saving data");
    }
  };

  // share link
  const shareLink = (id) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this hotel!",
          text: "I found this amazing hotel in OotyGo. Check it out!",
          url: `https://www.ootygo.in/hotel/${id}`, // Replace this with your actual URL
        })
        .catch((error) => {
          console.error("Error sharing the link:", error);
        });
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      // Clipboard API is supported
      navigator.clipboard
        .writeText(`https://www.ootygo.in/hotel/${id}`)
        .then(() => {
          alert("Link copied to clipboard. You can now share it!");
        })
        .catch((error) => {
          console.error("Error copying the link:", error);
        });
    } else {
      // Fallback: Prompt user to manually copy the link
      const textarea = document.createElement("textarea");
      textarea.value = `https://www.ootygo.in/hotel/${id}`;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        alert("Link copied to clipboard. You can now share it!");
      } catch (err) {
        console.error("Error copying the link to clipboard:", err);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  const shareLinkBus = (id) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this hotel!",
          text: "I found this amazing hotel in OotyGo. Check it out!",
          url: `https://www.ootygo.in/Business/${id}`, // Replace this with your actual URL
        })
        .catch((error) => {
          console.error("Error sharing the link:", error);
        });
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      // Clipboard API is supported
      navigator.clipboard
        .writeText(`https://www.ootygo.in/Business/${id}`)
        .then(() => {
          alert("Link copied to clipboard. You can now share it!");
        })
        .catch((error) => {
          console.error("Error copying the link:", error);
        });
    } else {
      // Fallback: Prompt user to manually copy the link
      const textarea = document.createElement("textarea");
      textarea.value = `https://www.ootygo.in/Business/${id}`;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        alert("Link copied to clipboard. You can now share it!");
      } catch (err) {
        console.error("Error copying the link to clipboard:", err);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  return (
    <>
      <div className="Mybooking">
        {/* <div>
          <span>Storage Usage: {dataLimit.toString().slice(0, 4)}MB/50MB</span>
          <p>Storage Usage: {size}MB/50MB</p>
        </div> */}
        <h1 className="Mybooking_title">My Ootygo Properties</h1>

        {ChangeDate ? (
          <>
            {Hotelmenu ? (
              <div className="hotel_date_card_Owner">
                <div className="hotel_Menu_card">
                  <button
                    onClick={() => setChangDate(false)}
                    className="Menu_cancel"
                  >
                    <MdOutlineCancel />
                  </button>
                  <h3>Update today menu for</h3>
                  <p>
                    <TiInfoOutline /> Old menu will be deleted
                  </p>
                  <h3>{ResName}</h3>
                  <form onSubmit={handleMenu}>
                    {Array.isArray(menuItems) &&
                      menuItems.map((item, index) => (
                        <div key={index} className="hotel_Menu_input">
                          <label>Dish Name</label>
                          <input
                            type="text"
                            name="dishName"
                            value={item.dishName}
                            onChange={(e) => handleChange(index, e)}
                            required
                            placeholder="Chicken Biriyani.."
                          />
                          <label>Dish Price</label>

                          <input
                            type="number"
                            name="price"
                            value={item.price}
                            onChange={(e) => handleChange(index, e)}
                            required
                            placeholder="₹ 190"
                          />
                          <label>Dish Image</label>
                          <input
                            type="file"
                            name=""
                            onChange={(e) => handleFileChange(index, e)}
                            required
                          />
                        </div>
                      ))}
                    <button
                      type="button"
                      onClick={addMenuItem}
                      className="Menu_Btns"
                    >
                      Add more
                    </button>
                    <button
                      type="button"
                      onClick={() => removeMenuItem()}
                      className="Menu_Btns"
                    >
                      Clear items
                    </button>
                    <button type="submit" className="Menu_Submit">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <div className="hotel_date_card_Owner">
                <div className="hotel_date_card_Owner_con">
                  <button
                    onClick={() => setChangDate(false)}
                    className="Date_cancel"
                  >
                    <MdOutlineCancel />
                  </button>
                  <h3>Change available date for</h3>
                  <h3>{hotelname}</h3>
                  <DatePicker
                    multiple
                    value={selectedDate}
                    onChange={handleDateChange}
                    placeholderText="Select a date"
                    minDate={new Date()}
                  />
                  <button onClick={handleSubmit} className="Book_Btns">
                    Booked
                  </button>
                  <button onClick={handleDelete} className="Book_Btns">
                    Free
                  </button>
                </div>
              </div>
            )}
          </>
        ) : null}

        <div className="MyAssets">
          <div className="MyAssets_Type">
            {loginUserHotel.map(({ id, name, Clicks, imgurl, rate }, index) => (
              <div key={index} className="Mybooking_contant_Vehicles">
                <img src={imgurl} alt="test-img" className="Stay_hotel_img" />
                <h3 className="Travel_Vehicle_title">{name}</h3>
                <div className="MyAssets_data_area">
                  <span>
                    Visitors
                    <b className="MyAsset_Click">
                      <LuView /> <span>{Clicks}</span>
                    </b>
                  </span>
                  <div className="Travel_Rete">
                    Pkg starts @ <b>{rate}</b>
                  </div>
                  <div className="Mybooking_Btns">
                    <span>Update date </span>
                    <button
                      className="Mybooking_Btn_Date"
                      onClick={() => {
                        setHotelMenu(false);
                        setChangDate(true);
                        setHotelId(id);
                        setHotelName(name);
                      }}
                    >
                      <CiCalendarDate />
                    </button>
                    <span>
                      <FaRegShareSquare
                        onClick={() => {
                          shareLink(id);
                        }}
                        className="Hotel_Share_Btn"
                      />
                    </span>
                    <button
                      onClick={() => deleteItem(id, name)}
                      className="Delete_Hotel"
                    >
                      <MdDeleteForever />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="MyAssets_Type">
            {loginUserRes.map(({ id, name, imgurl, Clicks }, index) => (
              <div key={index} className="Mybooking_contant_Vehicles">
                <img src={imgurl} alt="test-img" className="Stay_hotel_img" />
                <h3 className="Travel_Vehicle_title">{name}</h3>
                <div className="MyAssets_data_area">
                  <p>
                    Visitors
                    <b className="MyAsset_Click">
                      <LuView />
                      <span>{Clicks}</span>
                    </b>
                  </p>
                  <div className="Mybooking_Btns">
                    <span>Update Menu</span>
                    <span>
                      <button
                        className="Mybooking_Btn_Date"
                        onClick={() => {
                          setChangDate(true);
                          setHotelMenu(true);
                          setResId(id);
                          setResName(name);
                        }}
                      >
                        <MdMenuBook />
                      </button>
                    </span>

                    <button
                      onClick={() => deleteRes(id, name)}
                      className="Delete_Hotel"
                    >
                      <MdDeleteForever />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="MyAssets_Type">
            {loginUserVehicle.map(({ id, name, imgurl, pkg, car }, index) => (
              <div key={index} className="Mybooking_contant_Vehicles">
                <img src={imgurl} alt="test-img" className="Stay_hotel_img" />
                <h3 className="Travel_Vehicle_title">{name}</h3>
                <div className="MyAssets_data_area">
                  <div className="Travel_Rete">
                    Pkg starts @ <b>{pkg}</b>
                  </div>
                  <div className="Mybooking_Btns">
                    <span>Update date </span>
                    <button
                      className="Mybooking_Btn_Date"
                      onClick={() => {
                        setHotelMenu(false);
                        setChangDate(true);
                        setHotelId(id);
                        setHotelName(name);
                      }}
                    >
                      <CiCalendarDate />
                    </button>
                    <button
                      onClick={() => deleteItemvh(id, name, car)}
                      className="Delete_Hotel"
                    >
                      <MdDeleteForever />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="MyAssets_Type">
        {loginUserBus.map(({ id, name, imgurl, Clicks }, index) => (
          <div key={index} className="Mybooking_contant_Vehicles">
            <img src={imgurl} alt="test-img" className="Stay_hotel_img" />
            <h3 className="Travel_Vehicle_title">{name}</h3>
            <div className="MyAssets_data_area">
              <p>
                Visitors
                <b className="MyAsset_Click">
                  <LuView />
                  <span>{Clicks}</span>
                </b>
              </p>
              <div className="Mybooking_Btns">
                <span>
                  <FaRegShareSquare
                    onClick={() => {
                      shareLinkBus(id);
                    }}
                    className="Hotel_Share_Btn"
                  />
                </span>
                <button
                  onClick={() => deleteIBusiness(id, name)}
                  className="Delete_Hotel"
                >
                  <MdDeleteForever />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <p className="Mybooking_Add_Title">
        Add your properties below
        <span>
          <BsFillArrowDownCircleFill />
        </span>
      </p>
    </>
  );
}
export default Mybookings;
