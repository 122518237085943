import Footer from "../page/Footer";
import Navbar from "../page/Navbar";
import "./Food.css";
import { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ThreeDot } from "react-loading-indicators";
import AWS from "aws-sdk";
import { MdOutlineCancel } from "react-icons/md";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { RiHeartAdd2Fill } from "react-icons/ri";
import { useAuthenticator } from "@aws-amplify/ui-react";
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export default function Food() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { user } = useAuthenticator((context) => [context.user]);
  const [data, setData] = useState([]);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-restaurant",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setData(result.Items);
      }
    });
  }, []);

  // hotel Menu Items

  const [menu, setMenu] = useState(false);
  const [menuItem, setMenuItem] = useState([]);
  const [Resname, setResName] = useState("");
  const handleMenuItem = async (id, name) => {
    setResName(name);
    setMenuItem([]);
    setMenu(true);
    const dynamodbRes = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-restaurant-menu",
      Key: {
        id: Number(id),
        name: name,
      },
    };

    try {
      const result = await dynamodbRes.get(params).promise();
      if (result.Item) {
        setMenuItem(result.Item);
      } else {
        console.log("No Menu Item found");
      }
    } catch (err) {
      console.error("Error fetching data from DynamoDB:", err);
    }
  };
  const isArray = Array.isArray(menuItem);
  const items = isArray ? menuItem : Object.values(menuItem);

  // Post Res Clicks
  const handleMenuClicked = async (id, name) => {
    const dynamodbResClick = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-restaurant",
      Key: {
        id: Number(id),
        name: name,
      },
    };
    try {
      const results = await dynamodbResClick.get(params).promise();
      if (results.Item) {
        const newClicked = results.Item.Clicks;
        const newClicks = newClicked + 1;
        const paramsPut = {
          TableName: "ootygo-restaurant",
          Key: {
            id: Number(id),
            name: name,
          },
          UpdateExpression: "set Clicks = :newClicks",
          ExpressionAttributeValues: { ":newClicks": newClicks },
        };
        try {
          await dynamodbResClick.update(paramsPut).promise();
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLikePost = async (name, imgurl, location) => {
    const dynamoDBLP = new AWS.DynamoDB.DocumentClient();
    const Loginuser_Id = user?.signInDetails?.loginId;
    const Params = {
      TableName: "ootygo-my-fav-items",
      Item: {
        id: name,
        name: Loginuser_Id,
        imgurl: imgurl,
        location: location,
      },
    };
    try {
      await dynamoDBLP.put(Params).promise();
    } catch (error) {
      window.alert("signin to add to fav");
    }
  };
  return (
    <>
      <Helmet>
        <title>Best Restaurant in Ooty | Enjoy your dining adventures!</title>
        <meta
          name="description"
          content="Ooty trip lan | Ooty plan | Top place to visit in Ooty | Ooty trip | Best place in Ooty | Exploe Ooty | Hidden place in ooty | Ooty Timing | Place to visit in Ooty"
        />
        <meta
          name="keywords"
          content="rental car, ooty, ooty trip plan, best place in ooty, ooty visit, ooty site seeing, ooty resort, ooty cottage"
        />
        <meta name="google-adsense-account" content="ca-pub-6710626702056381"></meta>
      </Helmet>
      <div>
        <Navbar />
        <div className="Food_contant">
          <div className="Food_contant_title">
            <h1 id="Food_contant_title">See Ooty's Menu</h1>
          </div>
          {menu ? (
            <div className="Food_contant_item_details">
              <div className="Food_contant_item_detail">
                <h2>Today's menu from</h2>
                <p>
                  {Resname}{" "}
                  {/* <span>
                    <button className="Food_contant_Order_btn">
                      Order now
                    </button>
                  </span> */}
                </p>

                <button
                  onClick={() => {
                    setMenu(false);
                    setResName("");
                  }}
                >
                  <MdOutlineCancel />
                </button>
                <div>
                  {" "}
                  {items.length > 0 ? (
                    <div>
                      <table className="Menu_table">
                        {" "}
                        <thead></thead>
                        <tbody>
                          {" "}
                          {items.map((menu, menuIndex) =>
                            Object.values(menu).map(
                              ({ dishName, foodImage, price }, itemIndex) =>
                                dishName && price && foodImage ? (
                                  <tr key={`${menuIndex}-${itemIndex}`}>
                                    {" "}
                                    <td>
                                      <img
                                        src={foodImage}
                                        alt="Food"
                                        height="100px"
                                        width="100px"
                                        className="foodimg"
                                      />
                                    </td>
                                    <td>{dishName}</td>{" "}
                                    <td>
                                      <MdOutlineCurrencyRupee /> {price}
                                    </td>{" "}
                                  </tr>
                                ) : null
                            )
                          )}{" "}
                        </tbody>{" "}
                      </table>
                    </div>
                  ) : (
                    <div>No Menu Available 😕</div>
                  )}{" "}
                </div>
              </div>
            </div>
          ) : null}

          <div className="Food_contant_item">
            {data.length > 0 ? (
              data?.map(({ id, name, imgurl, location, rating }, index) => (
                <div className="Food_contant_items" key={index}>
                  <img className="Food_contant_img" src={imgurl} alt="" />
                  <h2 className="Food_contant_Item_title">{name}</h2>
                  <div className="Food_hotel_rating">
                    <div>
                      <span>Ratings: </span>
                      {Array.from({ length: rating }).map((_, index) => (
                        <FaStar key={index} className="RatingStar" />
                      ))}
                      <span>{rating}</span>
                    </div>

                    <span>
                      <Link
                        to={location}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLocationDot />
                      </Link>
                    </span>
                    <span
                      className="Hotel_Calander"
                      onClick={() => handleLikePost(name, imgurl, location)}
                    >
                      <RiHeartAdd2Fill className="Hotel_Share_Btn"/>
                    </span>
                  </div>
                  <button
                    onClick={() => {
                      handleMenuItem(id, name);
                      handleMenuClicked(id, name);
                    }}
                    className="Food_Hotel_Menu_Btn"
                  >
                    View Menu
                  </button>
                </div>
              ))
            ) : (
              <div className="Food_Contant_empty">
                <ThreeDot color="#32cd32" size="medium" text="" textColor="" />
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
