import React from "react";
// import axios from "axios";
import "./Subscription.css";
import { GoDotFill } from "react-icons/go";
import { useAuthenticator } from "@aws-amplify/ui-react";
// import { FaSquareWhatsapp } from "react-icons/fa6";
import AWS from "aws-sdk";
// import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { IoMdTrendingUp } from "react-icons/io";
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaLink } from "react-icons/fa6";
const SubscriptionComponent = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  // Razorpay Function
  const LoginUser = user?.signInDetails?.loginId;
  const [paid, setPaid] = useState(false);
  const [notPaid, setNotPaid] = useState(false);
  const subscriptionPlans = [
    {
      id: "plan_1",
      name: "Basic",
      price: (
        <div>
          <p className="Price">
            ₹ 99/Month <strike>₹ 198/Month</strike>
          </p>

          <p>
            <GoDotFill /> 1 Property
          </p>
          <p>
            <GoDotFill /> Listed Normally
          </p>
          <p>
            <GoDotFill /> Booking Enquirys
          </p>
          <p>
            <GoDotFill /> Shareable link for Property <FaLink />
          </p>
        </div>
      ),
      amount: 99,
      prop_count: 1,
      data_limit: 50,
    },
    {
      id: "plan_2",
      name: "Standard",
      price: (
        <div>
          <p className="Price">
            ₹ 999/Month <strike>₹ 1998/Month</strike>
          </p>

          <p>
            <GoDotFill /> 2 Property
          </p>
          <p>
            <GoDotFill /> Listed in top 10
          </p>
          <p>
            <GoDotFill /> Ad post on social media
          </p>
          <p>
            <GoDotFill /> Guaranteed Leads
          </p>
          <p>
            <GoDotFill /> Shareable link for Property <FaLink />
          </p>
        </div>
      ),
      amount: 999,
      prop_count: 2,
      data_limit: 100,
    },
    {
      id: "plan_3",
      name: "Premium",

      price: (
        <div>
          <p className="Price">
            ₹ 2,999/Month <strike>₹ 5,998/Month</strike>
          </p>

          <p>
            <GoDotFill /> 5 Property
          </p>
          <p>
            <GoDotFill /> Listed in top 5
          </p>
          <p>
            <GoDotFill /> Ad post on social media
          </p>

          <p>
            <GoDotFill /> Guaranteed Leads
          </p>
          <p>
            <GoDotFill /> Shareable link for Property <FaLink />
          </p>
          <p>
            <GoDotFill /> Showcase Video on Homepage
          </p>
          <p>
            <GoDotFill /> Expert dedicated assistant for BDS
          </p>
        </div>
      ),
      amount: 2999,
      prop_count: 5,
      data_limit: 2000,
    },
    {
      id: "plan_4",
      name: "Enterprise",

      price: (
        <div>
          <p className="Price">
            ₹ 15,000 <strike>₹ 30,000</strike> (EMI-available)
          </p>
          <p>
            <GoDotFill /> Dedicated website <br />
            (Custom Domain, SSL Certificate)
          </p>
          <p>
            <GoDotFill /> Dedicated App
          </p>
          <p>
            <GoDotFill /> SEO
          </p>
          <p>
            <GoDotFill /> Payment Gateway
          </p>
          <p>
            <GoDotFill /> Upto 100 Products
          </p>
          <p>
            <GoDotFill /> 20 GB Storage
          </p>
          <p>
            <GoDotFill /> Ad post on social media
          </p>
          <p>
            <GoDotFill /> Meta & Google Ads 1 month
          </p>
          <p>
            <GoDotFill /> Expert dedicated assistant for BDS
          </p>
          <p>
            <GoDotFill /> *Maintenance fees applicable/month
          </p>
        </div>
      ),
      amount: 15000,
      data_limit: 20000,
    },
  ];
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.head.appendChild(script);
    });
  };

  const handlePayment = async (plan) => {
    const scriptLoaded = await loadRazorpayScript();
    const RzpAK = process.env.REACT_APP_RAZORPAY_API_KEY;
    if (!scriptLoaded) {
      console.error("Razorpay SDK failed to load.");
      setNotPaid(true);
      return;
    }

    if (typeof window.Razorpay === "undefined") {
      console.error("Razorpay is not defined.");
      return;
    }
    if (!RzpAK) {
      console.error(
        "Razorpay API key is not set. Please check your .env file."
      );
      return;
    }
    if (!scriptLoaded) {
      console.error("Razorpay SDK failed to load.");
      return;
    }
    if (typeof window.Razorpay === "undefined") {
      console.error("Razorpay is not defined.");
      return;
    }

    const options = {
      key: RzpAK,
      amount: plan.amount * 100, // Amount in paise
      currency: "INR",
      name: "OotyGo - Subscription Plan",
      description: `Subscription Plan - ${plan.name}`,
      handler: function (response) {
        console.log(response);
        storePaymentDetails(response, plan);
        setPaid(true);
      },
      prefill: { name: "Your Name", email: LoginUser },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const storePaymentDetails = (response, plan) => {
    const dynamoDb = new AWS.DynamoDB.DocumentClient();

    const params = {
      TableName: "Subscription-User-list",
      Item: {
        email: LoginUser, // Replace with actual user's email
        payment_id: response.razorpay_payment_id,
        amount: plan.amount.toString(),
        plan: plan.name,
        prop_count: plan.prop_count,
        status: "Active",
        start_date: new Date().toISOString(),
        end_date: new Date(
          new Date().setDate(new Date().getDate() + 31)
        ).toISOString(),
      },
    };

    dynamoDb.put(params, (err, data) => {
      if (err) {
        console.error(
          "Unable to add payment in DynamoDb",
          JSON.stringify(err, null, 2)
        );
      } else {
        console.log("Added payment:", JSON.stringify(data, null, 2));
        window.location.reload();
      }
    });
    const dynamoDb2 = new AWS.DynamoDB.DocumentClient();

    const params2 = {
      TableName: "ootygo-tour-payments",
      Item: {
        payment_id: response.razorpay_payment_id,
        username: LoginUser,
        package_name: plan.name,
        amount: plan.amount,
        status: "Paid",
        date: new Date().toISOString(),
        data_limit: plan.data_limit,
      },
    };

    dynamoDb2.put(params2, (err, data) => {
      if (err) {
        console.error(
          "Unable to add payment in DynamoDb",
          JSON.stringify(err, null, 2)
        );
      } else {
        console.log("Added payment:", JSON.stringify(data, null, 2));
      }
    });
  };
  const SubscriptionPlan = ({ plan, handlePayment }) => (
    <div className="Sub_Plans">
      {" "}
      <h2 className="Sub_Plans_Name">{plan.name}</h2> <div>{plan.price}</div>
      <button onClick={() => handlePayment(plan)} className="Sub_Plans_Pay_btn">
        Pay & Grow <IoMdTrendingUp />
      </button>{" "}
    </div>
  );
  const SubscriptionPlans = ({ plans, handlePayment }) => (
    <div className="Sub_Plan">
      {plans.map((plan) => (
        <SubscriptionPlan
          key={plan.id}
          plan={plan}
          handlePayment={handlePayment}
        />
      ))}{" "}
    </div>
  );

  const calculateTimeLeft = () => {
    const difference = +new Date("2025-01-25") - +new Date(); // target date
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Min: Math.floor((difference / 1000 / 60) % 60),
        Sec: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}
      </span>
    );
  });

  return (
    <div>
      <h2 className="Sub_titel">Buy a plan to start</h2>

      <div className="Sub_offer">
        <span className="Beta_Version">Beta</span>
        <img
          src="https://i.pinimg.com/originals/79/8f/49/798f498960f4e3346bfe97b18f75b7a8.gif"
          alt="offer"
        />
        <span className="Sub_offer_Time">
          <p>Offer end's in</p>
          {timerComponents.length ? timerComponents : null}
        </span>
      </div>
      <div>
        <SubscriptionPlans
          plans={subscriptionPlans}
          handlePayment={handlePayment}
        />
      </div>
      <div
        className={`Pakage_pay_Status ${paid ? "paid" : "failed"} ${
          notPaid ? "notPaid" : "failed"
        }`}
      >
        <div className={paid ? "Paid_True" : "PaidFalse"}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 52 52"
            xmlSpace="preserve"
            fill="#000000"
            height="50"
            width="50px"
          >
            <circle className="circle" fill="#4CAF50" cx="26" cy="26" r="25" />
            <path
              className="check"
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="5"
              strokeMiterlimit="10"
              d="M14,27l7.8,7.8l16.7-16.7"
            />
            <style>
              {`
          .circle {
            animation: circle-animation 0.6s ease-out forwards;
          }
          .check {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: check-animation 0.6s ease-out forwards 0.6s;
          }
          @keyframes circle-animation {
            0% {
              stroke-dasharray: 0, 150;
              stroke-dashoffset: 0;
              opacity: 1;
            }
            100% {
              stroke-dasharray: 150, 150;
              stroke-dashoffset: -100;
              opacity: 1;
            }
          }
          @keyframes check-animation {
            to {
              stroke-dashoffset: 0;
            }
          }
        `}
            </style>
          </svg>

          <h4>Payment Success</h4>
        </div>
        <div className={notPaid ? "NotPaid_True" : "NotPaidFalse"}>
          <svg
            width="100"
            height="100"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"
              fill="#f44336"
            />
          </svg>
          <h4>Payment Failed</h4>
        </div>
      </div>
      <h5 className="Payment_Email">
        <img
          src="https://pinlearn.com/wp-content/uploads/2021/10/razorpay-who.png"
          alt="payment options"
        />
        After subscription, our dedicated BDS assistant will contact you within
        24 hours for assistance.
      </h5>

      <h5 className="Payment_Email">
        For assistance{" "}
        <button
          onClick={() => (window.location.href = `tel:8838724178`)}
          className="Sub_Assistance_Btn"
        >
          <MdOutlineSupportAgent />
        </button>
      </h5>
    </div>
  );
};

export default SubscriptionComponent;
