import React, { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import AWS from "aws-sdk";
import "./PostHotelData.css";
import { ClipLoader } from "react-spinners";
// import { useNavigate } from "react-router-dom";
import { LuInfo } from "react-icons/lu";
import { TiTick } from "react-icons/ti";
import { MdOutlineErrorOutline } from "react-icons/md";
// Configure AWS SDK
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();
const docClient = new AWS.DynamoDB.DocumentClient();

const PostHotelData = () => {
  const [selectedImages, setSelectedImages] = useState({
    imgurl: null,
    imgurl2: null,
    imgurl3: null,
    video: null,
  });
  const initialFormData = {
    id: "",
    name: "",
    imgurl: "",
    imgurl2: "",
    imgurl3: "",
    video: "",
    rate: 0,
    username: "",
    cell: "",
    Clicks: 0,
    about: "",
    location: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [keywords, setKeywords] = useState("");
  const [loading, setLoading] = useState(false);
  const [notsent, setNotsent] = useState(false);
  const [sent, setSent] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (user) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        username: user.signInDetails.loginId,
      }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setKeywords("");
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImages((prev) => ({ ...prev, [name]: event.target.result }));
      };
      reader.readAsDataURL(files[0]);
      setFormData({ ...formData, [name]: files[0] });
    }
  };

  const uploadFile = async (file) => {
    const folderName = formData.name;
    const params = {
      Bucket: "ootygo-asset-hotel",
      Key: `${folderName}/${file.name}`,
      Body: file,
    };
    const data = await s3.upload(params).promise();
    return data.Location;
  };

  // check sub and list function
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const getUserSubscription = async () => {
      const userName = user?.signInDetails.loginId;
      const params = {
        TableName: "Subscription-User-list",
        username: userName,
      };
      const data = await docClient.scan(params).promise();
      return data.Items.filter(
        (item) => item.email === user?.signInDetails.loginId
      );
    };

    const getUserPropertiesCount = async () => {
      const params = {
        TableName: "Ootygo-hotel",
        FilterExpression: "username = :username",
        ExpressionAttributeValues: { ":username": user?.signInDetails.loginId },
      };
      const data = await docClient.scan(params).promise();
      return data.Items.length;
    };

    const getUserResCount = async () => {
      const params = {
        TableName: "ootygo-restaurant",
        FilterExpression: "username = :username",
        ExpressionAttributeValues: { ":username": user?.signInDetails.loginId },
      };
      const data = await docClient.scan(params).promise();
      return data.Items.length;
    };

    const getUserTravelCount = async () => {
      const params = {
        TableName: "Ootygo-travel",
        FilterExpression: "username = :username",
        ExpressionAttributeValues: { ":username": user?.signInDetails.loginId },
      };
      const data = await docClient.scan(params).promise();
      return data.Items.length;
    };

    const getUserBusinessCount = async () => {
      const params = {
        TableName: "ootygo-business",
        FilterExpression: "username = :username",
        ExpressionAttributeValues: { ":username": user?.signInDetails.loginId },
      };
      const data = await docClient.scan(params).promise();
      return data.Items.length;
    };

    const uploadFiles = async (formData) => {
      const files = [
        formData.imgurl,
        formData.imgurl2,
        formData.imgurl3,
        formData.video,
      ];
      const uploadedFiles = await Promise.all(files.map(uploadFile));
      return uploadedFiles;
    };

    const saveData = async (formData, user, uploadedFiles) => {
      const params = {
        TableName: "Ootygo-hotel",
        Item: {
          ...formData,
          id: Number(formData.id),
          imgurl: uploadedFiles[0],
          imgurl2: uploadedFiles[1],
          imgurl3: uploadedFiles[2],
          video: uploadedFiles[3],
          rate: 0,
          username: user.signInDetails.loginId,
          about: formData.about,
          Clicks: 0,
        },
      };

      await docClient.put(params).promise();
    };

    try {
      const userSubscription = await getUserSubscription();
      if (!userSubscription) {
        throw new Error("User subscription data not found");
      }
      const userPropertiesCount = await getUserPropertiesCount();
      const userRestCount = await getUserResCount();
      const userTravelCount = await getUserTravelCount();
      const userBusinessCount = await getUserBusinessCount();
      const totalPropCountis =
        userPropertiesCount +
        userRestCount +
        userTravelCount +
        userBusinessCount;
      if (totalPropCountis >= userSubscription[0]?.prop_count) {
        // Display alert message and return
        alert(
          `You've reached your property limit of ${userSubscription[0]?.prop_count}. Upgrade your plan or contact support to add more.`
        );
        setLoading(false);
        return;
      }
      const uploadedFiles = await uploadFiles(formData);
      await saveData(formData, user, uploadedFiles);
      setSent(true);
      setFormData(initialFormData);
    } catch (error) {
      console.error("Error saving data:", error);
      setNotsent(true);
    } finally {
      setLoading(false);
    }
  };

  // Update id
  const [lastItemId, setLastItemId] = useState(0);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-hotel",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        const maxIdObject = result.Items?.reduce((prev, current) =>
          prev.id > current.id ? prev : current
        );
        setLastItemId(maxIdObject?.id + 1);
      }
    });
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className="Upload_hotel_form">
        <input
          type="number"
          name="id"
          value={(formData.id = lastItemId)}
          onChange={handleChange}
          placeholder="ID"
          required
          className="Hotel_id_value"
        />
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Resort | Cottage | Stay Name"
          required
        />
        <label className="Upload_Info">Image</label>
        <div className="Selected_Items">
          {selectedImages.imgurl && (
            <img
              src={selectedImages.imgurl}
              alt="Selected img 1"
              className="Select_Image"
            />
          )}{" "}
          {selectedImages.imgurl2 && (
            <img
              src={selectedImages.imgurl2}
              alt="Selected img 2"
              className="Select_Image"
            />
          )}{" "}
          {selectedImages.imgurl3 && (
            <img
              src={selectedImages.imgurl3}
              alt="Selected img 3"
              className="Select_Image"
            />
          )}{" "}
          {selectedImages.video && (
            <video
              src={selectedImages.video}
              alt="Selected video"
              className="Select_Image"
            />
          )}{" "}
        </div>
        <input type="file" name="imgurl" onChange={handleFileChange} required />{" "}
        <input
          type="file"
          name="imgurl2"
          onChange={handleFileChange}
          required
        />{" "}
        <input
          type="file"
          name="imgurl3"
          onChange={handleFileChange}
          required
        />{" "}
        <label className="Upload_Info">*Video of 30 seconds</label>{" "}
        <input type="file" name="video" onChange={handleFileChange} required />
        <input
          type="number"
          name="rate"
          value={formData.rate}
          onChange={handleChange}
          placeholder="Starting Rate"
          required
        />
        <input
          type="text"
          name="cell"
          value={formData.cell}
          onChange={handleChange}
          placeholder="Mobile No"
          required
        />
        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleChange}
          placeholder="Location Url https://www.google.com/maps/place/"
          required
        />
        <span className="Asset_Keyword">
          <textarea
            type="text"
            name="about"
            value={formData.about}
            onChange={handleChange}
            placeholder={
              keywords === ""
                ? "About (Add keywords for top listing)"
                : keywords
            }
            required
          />
          <span>
            <LuInfo
              onClick={() =>
                setKeywords(
                  `Ooty Resort, Luxury Stay in Ooty, Best Resorts in Ooty, Ooty Hill Station Resort, Ooty Weekend Getaway, Eco-friendly Resort in Ooty, Ooty Spa Resort, Family-Friendly Resort in Ooty, Romantic Getaway in Ooty, Ooty Adventure Resort, Ooty Luxury Villa, Mountain View Resort in Ooty, Ooty Boutique Resort, Ooty Adventure Activities`
                )
              }
            />
          </span>
        </span>
        <div>
          <button type="submit" className="Upload_btn">
            <span>{loading && <ClipLoader />}</span>
            <span>{sent && <TiTick />}</span>
            <span>
              {notsent && (
                <>
                  <MdOutlineErrorOutline /> Error: 400
                </>
              )}
            </span>

            <span>{notsent ? null : <span>Submit</span>}</span>
          </button>
        </div>
      </form>
    </>
  );
};

export default PostHotelData;
