import Footer from "../page/Footer";
import Navbar from "../page/Navbar";
import "./BusinessIn.css";
import "./Stay.css";
import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import "./Travel.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { FaSearch } from "react-icons/fa";
import { SlCallOut } from "react-icons/sl";
import { FaStar } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa6";
import { MdOutlineCancel } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import { CiCircleInfo } from "react-icons/ci";
import { BiSolidMessageAdd } from "react-icons/bi";
import { RiSendPlane2Line } from "react-icons/ri";
import { ThreeDot } from "react-loading-indicators";
import { FaLocationDot } from "react-icons/fa6";
import { FaRegShareSquare } from "react-icons/fa";
//Sliders
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import nothing from "../assets/Nothing.gif";
import FileSendOk from "../assets/images/filesend.mp4";
import { RiHeartAdd2Fill } from "react-icons/ri";
// Initialize AWS SDK
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export default function BusinessIn() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState([]);
  const { user } = useAuthenticator((context) => [context.user]);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/SignUp");
  };

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-business",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setData(result.Items);
      }
    });
  }, []);

  // Getting Reviesw

  const [reviews, setReviews] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-hotel-reviews",
    };
    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setReviews(result.Items);
      }
    });
    // Reload the reviews

    if (isSubmitted) {
      dynamodb.scan(params, (err, result) => {
        if (err) {
          console.error("Error fetching data from DynamoDB:", err);
        } else {
          setReviews(result.Items);
        }
      });

      setIsSubmitted(false);
    }
  }, [isSubmitted]);

  // const shuffleHotel = (array) => {
  //   for (let i = array.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [array[i], array[j]] = [array[j], array[i]];
  //   }
  //   return array;
  // };

  //Load more

  const [toList, setToList] = useState(8);
  const shuffledData = [...data].slice(0, toList);
  const maximumData = data.length === shuffledData.length;

  // Image Slide

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    zIndex: 10,
    color: "black",
    arrows: false,
  };

  // Search
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearched] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const items = data;

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value === "") {
      setSearched(false);
    }
  };
  // search function

  const handleSearch = () => {
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      let minRange = null;
      let maxRange = null;
      const rangeMatch = searchLower.match(/(\d+) to (\d+)/);
      if (rangeMatch) {
        minRange = Number(rangeMatch[1]);
        maxRange = Number(rangeMatch[2]);
      }

      const results = items.filter((item) => {
        const matchesName = item.name.toLowerCase().includes(searchLower);
        const matchesAbout =
          item.about && item.about.toLowerCase().includes(searchLower);
        const matchesAny =
          item.about &&
          item.about
            .toLowerCase()
            .split(",")
            .some((term) => term.includes(searchLower));

        const matchesSplitTerms = searchLower
          .split(",")
          .some((term) => item.name.toLowerCase().includes(term));
        const matchesRange = minRange !== null && maxRange !== null;

        return (
          matchesName ||
          matchesAbout ||
          matchesAny ||
          matchesRange ||
          matchesSplitTerms
        );
      });
      setFilteredItems(results);
      setSearched(true);
    } else {
      return null;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const [displayDetails, setDisplayDetails] = useState([]);

  const [isDisplay, setIsDisplay] = useState(false);

  const [postReview, setPostReview] = useState(false);

  // const [rating, setRating] = useState(0);

  // Post Review

  const docClient = new AWS.DynamoDB.DocumentClient();
  const initialFormData = {
    id: "",
    name: "",
    rating: 1,
    review: "",
    Date: "",
    username: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const HotelId = displayDetails[0];
    const cDate = new Date();
    const year = cDate.getFullYear();
    const month = cDate.getMonth();
    const day = cDate.getDate();

    setFormData({
      ...formData,
      [name]: value,
      id: HotelId,
      Date: `${year}-${month}-${day}`,
      username: user?.signInDetails?.loginId,
    });
    // setRating(e.target.value);
  };
  const [sending, setSending] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (formData.id > 0)
    try {
      setSending(true);
      const params = {
        TableName: "ootygo-hotel-reviews",
        Item: {
          ...formData,
        },
      };

      await docClient.put(params).promise();
      setTimeout(() => {
        setSending(false);
        setIsSubmitted(true);
      }, 2700);
      setFormData(initialFormData);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // call btn clicks posting

  const handleCallClick = async (id, name) => {
    const dynamoDBC = new AWS.DynamoDB.DocumentClient();
    const getParams = {
      TableName: "ootygo-business",
      Key: { id: id, name: name },
    };
    try {
      const data = await dynamoDBC.get(getParams).promise();
      const currentClicks = data?.Item.Clicks;
      const NewClicks = currentClicks + 1;
      const updateParams = {
        TableName: "ootygo-business",
        Key: { id: id, name: name },
        UpdateExpression: "set Clicks = :newClick",
        ExpressionAttributeValues: { ":newClick": NewClicks },
        ReturnValues: "UPDATED_NEW",
      };
      await dynamoDBC.update(updateParams).promise();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // Handle Liked Items

  const handleLikePost = async (name, cell, imgurl) => {
    const dynamoDBLP = new AWS.DynamoDB.DocumentClient();
    const Loginuser_Id = user?.signInDetails?.loginId;
    const Params = {
      TableName: "ootygo-my-fav-items",
      Item: {
        id: name,
        name: Loginuser_Id,
        imgurl: imgurl,
        cell: Number(cell),
      },
    };
    if (user) {
      try {
        await dynamoDBLP.put(Params).promise();
      } catch (error) {
        console.error("Not loged in", error);
      }
    } else {
      window.alert("Signin to add to favorites");
    }
  };

  // share link
  const shareLink = (id) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this hotel!",
          text: "I found this amazing hotel in OotyGo. Check it out!",
          url: `https://www.ootygo.in/Business/${id}`, // Replace this with your actual URL
        })
        .catch((error) => {
          console.error("Error sharing the link:", error);
        });
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      // Clipboard API is supported
      navigator.clipboard
        .writeText(`https://www.ootygo.in/Business/${id}`)
        .then(() => {
          alert("Link copied to clipboard. You can now share it!");
        })
        .catch((error) => {
          console.error("Error copying the link:", error);
        });
    } else {
      // Fallback: Prompt user to manually copy the link
      const textarea = document.createElement("textarea");
      textarea.value = `https://www.ootygo.in/Business/${id}`;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        alert("Link copied to clipboard. You can now share it!");
      } catch (err) {
        console.error("Error copying the link to clipboard:", err);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>Top Business in Ooty</title>
        <meta
          name="description"
          content="Explore the best business opportunities in Ooty! Discover top hotel booking services, prime locations, and exceptional hospitality for your next venture."
        />
        <meta
          name="keywords"
          content="Ooty business opportunities, Ooty hotel booking, Ooty business guide, Ooty hospitality, best places in Ooty, visit Ooty, Ooty travel guide, Ooty accommodations"
        />
        <meta
          name="google-adsense-account"
          content="ca-pub-6710626702056381"
        ></meta>
      </Helmet>

      <Navbar />
      <div className="Businessin_Conatnt">
        <h1 className="Businessin_Conatnt_Title">Top Business In Ooty</h1>

        <div className="Business_contant_search">
          <div className="Business_search_bar_item">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="Business_search_bar"
            />
            <button className="Travel_Search_Btn">
              {search ? (
                <MdOutlineCancel
                  onClick={() => {
                    setSearched(false);
                    setFilteredItems([]);
                  }}
                />
              ) : (
                <FaSearch onClick={handleSearch} />
              )}
            </button>
          </div>
        </div>

        {search ? (
          <div>
            <h2 className="Travel_Search_Title">Search Results</h2>
            <ul className="Travel_Search_Result">
              {" "}
              {filteredItems > [""] ? (
                filteredItems.map(
                  (
                    {
                      id,
                      name,
                      imgurl,
                      imgurl2,
                      imgurl3,
                      video,
                      cell,
                      about,
                      location,
                    },
                    index
                  ) => (
                    <div key={index} className="Stay_Hotels_items">
                      <div
                        className="slider-container"
                        onClick={() => {
                          setDisplayDetails([
                            id,
                            name,
                            imgurl,
                            imgurl2,
                            imgurl3,
                            video,
                            cell,
                            about,
                            location,
                          ]);
                          setIsDisplay(true);
                        }}
                      >
                        <Slider {...settings1}>
                          <div>
                            <img
                              src={imgurl}
                              alt="Slide 1"
                              className="Stay_Slide_img"
                            />
                          </div>
                          <div>
                            <img
                              src={imgurl2}
                              alt="Slide 2"
                              className="Stay_Slide_img"
                            />
                          </div>
                          <div>
                            <img
                              src={imgurl3}
                              alt="Slide 3"
                              className="Stay_Slide_img"
                            />
                          </div>
                          <div>
                            <video width="280=px" height="200px" controls muted>
                              <source src={video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </Slider>
                      </div>
                      <h3 className="Stay_hotel_title">{name}</h3>

                      <span className="Hotel_Calander">
                        <span>
                          <FaRegShareSquare
                            onClick={() => {
                              shareLink(id);
                            }}
                            className="Hotel_Share_Btn"
                          />
                        </span>
                        <span
                          className="Hotel_Calander"
                          onClick={() => handleLikePost(name, cell, imgurl)}
                        >
                          <RiHeartAdd2Fill className="Hotel_Share_Btn" />
                        </span>
                        <span className="Business_call_Btn">
                          <b
                            onClick={
                              user
                                ? () => {
                                    handleCallClick(id, name);
                                    window.location.href = `tel:${cell}`;
                                  }
                                : handleClick
                            }
                          >
                            <SlCallOut />
                          </b>
                        </span>
                      </span>
                    </div>
                  )
                )
              ) : (
                <div>
                  <span className="Vehicle_Search_0">
                    <img src={nothing} alt="Nothing Found" />
                  </span>
                </div>
              )}
            </ul>
            <hr />
          </div>
        ) : null}
        {data ? (
          <div className="Stay_contant_Hotels">
            <div className={isDisplay ? "card" : "noCard"}>
              <div
                onClick={() => {
                  setIsDisplay(false);
                  setPostReview(true);
                }}
                className="DisplayHotelCancel"
              >
                <ImCancelCircle />
              </div>
              <div>
                <h1 className="Stay_Hotel_Display_Title">
                  {displayDetails[1]}
                </h1>
              </div>
              <div className="Stay_Hotel_Display">
                <div className="Stay_Hotel_Display_Imgs">
                  <img
                    src={displayDetails[2]}
                    alt="Hotel 1"
                    className="Stay_Hotel_Details_Img"
                  />{" "}
                  <img
                    src={displayDetails[3]}
                    alt="Hotel 2"
                    className="Stay_Hotel_Details_Img"
                  />{" "}
                  <img
                    src={displayDetails[4]}
                    alt="Hotel 3"
                    className="Stay_Hotel_Details_Img"
                  />{" "}
                  <video
                    src={displayDetails[5]}
                    alt="Hotel 3"
                    className="Stay_Hotel_Details_Img"
                    controls
                    muted
                    controlsList="nodownload"
                  ></video>
                </div>
                <div>
                  <h3 className="Stay_Hotel_Display_Price">
                    <span className="Hotel_Calander">
                      <a href={displayDetails[8]}>
                        <FaLocationDot className="Hotel_Share_Btn" />
                      </a>
                      <FaRegShareSquare
                        onClick={() => {
                          shareLink(displayDetails[0]);
                        }}
                        className="Hotel_Share_Btn"
                      />
                    </span>
                  </h3>

                  <span className="Business_call_Btn_2">
                    {" "}
                    <b
                      onClick={
                        user
                          ? () =>
                              (window.location.href = `tel:${displayDetails[7]}`)
                          : handleClick
                      }
                    >
                      <SlCallOut />
                    </b>
                  </span>
                  <div className="Stay_Hotel_Display_Ratings">
                    <span>
                      {reviews.length > 0 ? (
                        (() => {
                          const filteredReviews = reviews.filter(
                            ({ id }) => id === displayDetails[0]
                          );
                          const maxRating =
                            filteredReviews.length > 0
                              ? Math.max(
                                  ...filteredReviews.map(({ rating }) => rating)
                                )
                              : null;
                          return maxRating !== null ? (
                            <div key={displayDetails[0]}>
                              {Array.from({ length: maxRating }).map(
                                (_, index) => (
                                  <FaStar key={index} className="RatingStar" />
                                )
                              )}
                              <span className="RatingStar"> {maxRating} </span>
                            </div>
                          ) : (
                            <span className="RatingStar">
                              <FaRegStar />
                              <FaRegStar />
                              <FaRegStar />
                              <FaRegStar />
                              <FaRegStar />
                            </span>
                          );
                        })()
                      ) : (
                        <span className="RatingStar">
                          <FaRegStar />
                          <FaRegStar />
                          <FaRegStar />
                          <FaRegStar />
                          <FaRegStar />
                        </span>
                      )}
                    </span>
                  </div>
                  <div>
                    <p className="Stay_Hotel_Display_About">
                      <CiCircleInfo /> {displayDetails[7]}
                    </p>
                  </div>
                  <div className="Stay_Hotel_Display_Reviews">
                    <hr />
                    <h4>Reviews</h4>
                    <span
                      className={
                        postReview ? "Stay_Display_call" : "Stay_Display_Post"
                      }
                    >
                      <span>
                        {postReview ? (
                          <BiSolidMessageAdd
                            onClick={() => {
                              setPostReview(false);
                            }}
                            className="Post_btns_msg"
                          />
                        ) : null}
                      </span>
                      <div>
                        <form onSubmit={user ? handleSubmit : handleClick}>
                          <ImCancelCircle
                            onClick={() => setPostReview(true)}
                            className="Post_btns"
                          />{" "}
                          <input
                            type="number"
                            value={displayDetails[0]}
                            onChange={handleChange}
                            name="id"
                            disabled
                            className="Review_Hotel_Name"
                          />{" "}
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            required
                          />{" "}
                          <label htmlFor="customRange2" className="form-label">
                            {" "}
                            Rating: {formData.rating}{" "}
                            {formData.rating &&
                              Array.from({ length: formData.rating }).map(
                                (_, index) => (
                                  <FaStar key={index} className="RatingStar" />
                                )
                              )}
                          </label>{" "}
                          <input
                            type="range"
                            className="form-range"
                            min="0"
                            max="5"
                            id="customRange2"
                            name="rating"
                            value={formData.rating}
                            onChange={handleChange}
                            required
                          />{" "}
                          <textarea
                            name="review"
                            value={formData.review}
                            placeholder="Type something..."
                            onChange={handleChange}
                          ></textarea>{" "}
                          <br />{" "}
                          <button type="submit" className="Post_btns">
                            {sending ? (
                              <video
                                src={FileSendOk}
                                autoPlay
                                className="Post_Ok"
                              ></video>
                            ) : (
                              <RiSendPlane2Line className="Post_btns_post" />
                            )}
                          </button>
                        </form>
                      </div>
                    </span>
                    <div>
                      <div>
                        {reviews.length > 0 ? (
                          reviews.map(({ id, name, rating, review }, index) =>
                            displayDetails[0] === id ? (
                              <div
                                key={index}
                                className="Stay_Hotel_Display_Review"
                              >
                                <h4>{name}</h4>
                                <div>
                                  <span>
                                    {Array.from({ length: rating }).map(
                                      (_, index) => (
                                        <FaStar
                                          key={index}
                                          className="RatingStar"
                                        />
                                      )
                                    )}
                                  </span>

                                  <span>{rating}</span>
                                </div>
                                <p>{review}</p>
                              </div>
                            ) : null
                          )
                        ) : (
                          <span>Add 1st review</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {shuffledData.length > 0 ? (
              shuffledData.map(
                (
                  {
                    id,
                    name,
                    imgurl,
                    imgurl2,
                    imgurl3,
                    video,
                    cell,
                    about,
                    location,
                  },
                  index
                ) => (
                  <div key={index} className="Stay_Hotels_items">
                    <div
                      className="slider-container"
                      onClick={() => {
                        setDisplayDetails([
                          id,
                          name,
                          imgurl,
                          imgurl2,
                          imgurl3,
                          video,
                          cell,
                          about,
                          location,
                        ]);
                        setIsDisplay(true);
                        handleCallClick(id, name);
                      }}
                    >
                      <Slider {...settings1}>
                        <div>
                          <img
                            src={imgurl}
                            alt="Slide 1"
                            className="Stay_Slide_img"
                          />
                        </div>
                        <div>
                          <img
                            src={imgurl2}
                            alt="Slide 2"
                            className="Stay_Slide_img"
                          />
                        </div>
                        <div>
                          <img
                            src={imgurl3}
                            alt="Slide 3"
                            className="Stay_Slide_img"
                          />
                        </div>
                        <div>
                          <video width="280=px" height="200px" controls muted>
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Slider>
                    </div>
                    <h3 className="Stay_hotel_title">{name}</h3>

                    <span>
                      <span className="Hotel_Calander">
                        <span>
                          <FaRegShareSquare
                            onClick={() => {
                              shareLink(id);
                            }}
                            className="Hotel_Share_Btn"
                          />
                        </span>
                        <span
                          className="Hotel_Calander"
                          onClick={() => handleLikePost(name, cell, imgurl)}
                        >
                          <RiHeartAdd2Fill className="Hotel_Share_Btn" />
                        </span>
                      </span>
                    </span>

                    <span className="Business_call_Btn_1">
                      <b
                        onClick={
                          user
                            ? () => {
                                handleCallClick(id, name);
                                window.location.href = `tel:${cell}`;
                              }
                            : handleClick
                        }
                      >
                        <SlCallOut />
                      </b>
                    </span>
                  </div>
                )
              )
            ) : (
              <div className="Places_contant_item_empty">
                <ThreeDot color="#32cd32" size="medium" text="" textColor="" />
              </div>
            )}
          </div>
        ) : (
          <div className="Places_contant_item_empty">
            <ThreeDot color="#32cd32" size="medium" text="" textColor="" />
          </div>
        )}
        <div className="Load_more_btn">
          <button
            className={toList >= 9 ? "visible" : "hidden"}
            onClick={() => setToList((prev) => prev - 8)}
          >
            Show Less
          </button>
          <button
            className={maximumData ? "hidden" : "visible"}
            onClick={() => setToList((prev) => prev + 8)}
          >
            Load More
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
}
