import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import "./PostHotelData.css";
import { ClipLoader } from "react-spinners";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { TiTick } from "react-icons/ti";
import { MdOutlineAirlineSeatReclineExtra } from "react-icons/md";
// Configure AWS SDK
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

const docClient = new AWS.DynamoDB.DocumentClient();

const PostVehicleData = () => {
  const [sent, setSent] = useState(false);
  const [cab, setCab] = useState("4");
  const handleCab = (event) => {
    setCab(event.target.value);
  };
  const initialFormData = {
    id: "",
    name: "",
    car: "",
    imgurl: "",
    number: "",
    pkg: "",
    sitting: cab,
    username: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const { user } = useAuthenticator((context) => [context.user]);
  useEffect(() => {
    if (user) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        username: user.signInDetails.loginId,
      }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "number" ? Number(value) : value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const uploadFile = async (file) => {
    const folderName = formData.name;
    const params = {
      Bucket: "ootygo-travel-partner",
      Key: `${folderName}/${file.name}`,
      Body: file,
    };
    const data = await s3.upload(params).promise();
    return data.Location;
  };

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const getUserSubscription = async () => {
      const userName = user?.signInDetails.loginId;
      const params = {
        TableName: "Subscription-User-list",
        username: userName,
      };
      const data = await docClient.scan(params).promise();
      return data.Items.filter(
        (item) => item.email === user?.signInDetails.loginId
      );
    };

    const getUserPropertiesCount = async () => {
      const params = {
        TableName: "Ootygo-hotel",
        FilterExpression: "username = :username",
        ExpressionAttributeValues: { ":username": user?.signInDetails.loginId },
      };
      const data = await docClient.scan(params).promise();
      return data.Items.length;
    };

    const getUserResCount = async () => {
      const params = {
        TableName: "ootygo-restaurant",
        FilterExpression: "username = :username",
        ExpressionAttributeValues: { ":username": user?.signInDetails.loginId },
      };
      const data = await docClient.scan(params).promise();
      return data.Items.length;
    };

    const getUserTravelCount = async () => {
      const params = {
        TableName: "Ootygo-travel",
        FilterExpression: "username = :username",
        ExpressionAttributeValues: { ":username": user?.signInDetails.loginId },
      };
      const data = await docClient.scan(params).promise();
      return data.Items.length;
    };
    const getUserBusinessCount = async () => {
      const params = {
        TableName: "ootygo-business",
        FilterExpression: "username = :username",
        ExpressionAttributeValues: { ":username": user?.signInDetails.loginId },
      };
      const data = await docClient.scan(params).promise();
      return data.Items.length;
    };
    try {
      const userSubscription = await getUserSubscription();
      if (!userSubscription) {
        throw new Error("User subscription data not found");
      }
      const userPropertiesCount = await getUserPropertiesCount();
      const userRestCount = await getUserResCount();
      const userTravelCount = await getUserTravelCount();
      const userBusinessCount = await getUserBusinessCount();
      const totalPropCountis =
        userPropertiesCount + userRestCount + userTravelCount + userBusinessCount;
      if (totalPropCountis >= userSubscription[0]?.prop_count) {
        // Display alert message and return
        alert(
          `You've reached your property limit of ${userSubscription[0]?.prop_count}. Upgrade your plan or contact support to add more.`
        );
        setLoading(false);
        return;
      }
      const imgurl = await uploadFile(formData.imgurl);

      const params = {
        TableName: "Ootygo-travel",
        Item: {
          ...formData,
          imgurl,
        },
      };

      await docClient.put(params).promise();
      setLoading(false);
      setSent(true);
      setFormData(initialFormData);
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Failed to save data.");
      setLoading(false);
    }
  };
  // Update Id
  const [lastItemId, setLastItemId] = useState(0);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-travel",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        const maxIdObject = result.Items?.reduce(
          (prev, current) => (prev.id > current.id ? prev : current),
          { id: -Infinity }
        );

        if (maxIdObject && maxIdObject.id !== -Infinity) {
          const maxId = Number(maxIdObject.id);
          setLastItemId(maxId + 1);
        } else {
          setLastItemId(1); // Handle the case where Items is empty
        }
      }
    });
  }, []);
  return (
    <form onSubmit={handleSubmit} className="Upload_hotel_form">
      <input
        type="text"
        name="id"
        value={(formData.id = lastItemId.toString())}
        onChange={handleChange}
        placeholder="ID"
        required
        className="Hotel_id_value"
      />
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Travels Name"
        required
      />

      <input
        type="text"
        name="car"
        value={formData.car}
        onChange={handleChange}
        placeholder="Vehicle Name"
        required
      />

      <label className="Upload_Info">Vehicle Image</label>
      <input type="file" name="imgurl" onChange={handleFileChange} required />

      <input
        type="number"
        name="number"
        value={formData.number}
        onChange={handleChange}
        placeholder="Mobile No"
        required
      />
      <input
        type="text"
        name="pkg"
        value={formData.pkg}
        onChange={handleChange}
        placeholder="Starting Rate"
        required
      />
      <label htmlFor="">
        Seating{" "}
        <MdOutlineAirlineSeatReclineExtra className="Travel_Seat_Icon" />
      </label>
      <select
        name="car"
        id=""
        onChange={handleCab}
        required
        className="Travel_Seating_Select"
      >
        <option value="4">4</option>
        <option value="7">7</option>
        <option value="9">9</option>
        <option value="12">12</option>
        <option value="16">16</option>
        <option value="18">18</option>
        <option value="20">20</option>
        <option value="22">22</option>
        <option value="26">26</option>
        <option value="30">30</option>
        <option value="45">45</option>
        <option value="50">50</option>
        <option value="60">60</option>
      </select>
      <br />
      <div>
        <button type="submit" className="Upload_btn">
          <span>{loading && <ClipLoader />}</span>
          <span>{sent && <TiTick />}</span>
          <span>Submit</span>
        </button>
      </div>
    </form>
  );
};

export default PostVehicleData;
