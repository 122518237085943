import "./Support.css";
import Navbar from "../../page/Navbar";
import Footer from "../../page/Footer";
import { Helmet } from "react-helmet";
export default function Support() {
  return (
    <>
      <Helmet>
        <title>OotyGo Support</title>
        <meta
          name="description"
          content="Direct Hotel Booking In Ooty | Best Booking Service In Ooty | Free Hotel Booking In Ooty"
        />
        <meta
          name="keywords"
          content="ooty hotel booking, ooty, ooty trip plan, best place in ooty, ooty visit, ooty site seeing, ooty resort, ooty cottage"
        />
      </Helmet>
      <Navbar />
      <div className="Support_contant">
        <h1 className="Support_titel">For Support</h1>
        <h4>Email: ootygo.official@gmail.com</h4>
        <h4>
          Call: Support
          <button onClick={() => (window.location.href = `tel:8838724178`)}>
            team
          </button>
           
        </h4>
        <p>All days 10 Am to 6 Pm</p>
      </div>
      <Footer />
    </>
  );
}
