import Footer from "../page/Footer";
import Navbar from "../page/Navbar";
import "./Plan.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
export default function Plan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const [isScrolled, setIsScrolled] = useState(false);
  // const handleScroll = () => {
  //   console.log("called");
  //   if (window.scrollY > 60) {
  //     setIsScrolled(true);
  //   } else {
  //     setIsScrolled(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // const [isScrolled2, setIsScrolled2] = useState(false);
  // const handleScroll2 = () => {
  //   if (window.scrollY > 510) {
  //     setIsScrolled2(true);
  //   } else {
  //     setIsScrolled2(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll2);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll2);
  //   };
  // }, []);
  // const [isScrolled3, setIsScrolled3] = useState(false);
  // const handleScroll3 = () => {
  //   if (window.scrollY > 970) {
  //     setIsScrolled3(true);
  //   } else {
  //     setIsScrolled3(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll3);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll3);
  //   };
  // }, []);

  // const [isScrolled4, setIsScrolled4] = useState(false);
  // const handleScroll4 = () => {
  //   if (window.scrollY >= 1450) {
  //     setIsScrolled4(true);
  //   } else {
  //     setIsScrolled4(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll4);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll4);
  //   };
  // }, []);

  return (
    <>
      <Helmet>
        <title>Ooty Trip Plan</title>
        <meta
          name="description"
          content="Ooty trip lan | Ooty plan | Top place to visit in Ooty | Ooty trip | Best place in Ooty | Exploe Ooty | Hidden place in ooty | Ooty Timing | Place to visit in Ooty"
        />
        <meta
          name="keywords"
          content="ooty hotel booking, ooty, ooty trip plan, best place in ooty, ooty visit, ooty site seeing, ooty resort, ooty cottage"
        />
        <meta name="google-adsense-account" content="ca-pub-6710626702056381"></meta>
      </Helmet>
      <Navbar />
      <div className="Task_Contant">
        <h1 className="Task_Contant_Title">OotyGo Trip Planer</h1>
        <div className="Task_Item">
          <div className="Task_Items">
            <div className="Plan-1">
              <h3 className="Plan_1_Title">
                Day 1: Arrival and Local Sightseeing
              </h3>
              <video
                src="https://videos.pexels.com/video-files/4254119/4254119-uhd_2732_1440_24fps.mp4"
                className="visible-video"
                autoPlay
                loop
                muted
              ></video>
              <div className="Task_table">
                <ul>
                  <li>
                    Reach Ooty via:
                    <ul>
                      <li>Coimbatore (90 km)</li>
                      <li>Mettupalayam (50 km)</li>
                      <li>Mysuru - Ooty Rd</li>
                    </ul>
                  </li>
                  <li>Check-in to your hotel and freshen up.</li>
                  <li>
                    Visit:
                    <ul>
                      <li>Botanical Garden (10 am - 5 pm)</li>
                      <li>Ooty Lake (10 am - 6 pm)</li>
                      <li>Take a boat ride (10 am - 6 pm)</li>
                      <li>Thread Garden (10 am - 5 pm)</li>
                      <li>Karnataka Park (10 am - 5 pm)</li>
                    </ul>
                  </li>
                  <li>Enjoy shopping at Commercial Road</li>
                </ul>
              </div>
            </div>
            <div className="Plan-1">
              <h3 className="Plan_1_Title">Day 2: Ooty Sightseeing</h3>
              <video
                src="https://videos.pexels.com/video-files/28322182/12360728_2560_1440_24fps.mp4"
                className="visible-video"
                autoPlay
                loop
                muted
              ></video>
              <div className="Task_table">
                <ul>
                  <li>
                    Doddabetta Peak (9 am - 5 pm)
                    <ul>
                      <li>Highest point in Ooty</li>
                    </ul>
                  </li>
                  <li>
                    Tea Museum (10 am - 5 pm)
                    <ul>
                      <li>Learn about tea production</li>
                    </ul>
                  </li>
                  <li>
                    Rose Garden (10 am - 5 pm)
                    <ul>
                      <li>Stunning views</li>
                    </ul>
                  </li>
                  <li>
                    Government Museum (10 am - 5 pm)
                    <ul>
                      <li>Insight into Ooty's history</li>
                    </ul>
                  </li>
                  <li>Evening walk at Charring Cross</li>
                </ul>
              </div>
            </div>
            <div className="Plan-1">
              <h3 className="Plan_1_Title">Day 3: Excursions</h3>
              <video
                src="https://videos.pexels.com/video-files/28633939/12436883_640_360_60fps.mp4"
                className="visible-video"
                autoPlay
                loop
                muted
              ></video>
              <div className="Task_table">
                <ul>
                  <li>
                    Sim's Park (9 am - 5 pm)
                    <ul>
                      <li>Beautiful gardens</li>
                    </ul>
                  </li>
                  <li>
                    Dolphin's Nose (9 am - 5 pm)
                    <ul>
                      <li>Stunning views</li>
                    </ul>
                  </li>
                  <li>
                    Lamb's Rock (9 am - 5 pm)
                    <ul>
                      <li>Panoramic views</li>
                    </ul>
                  </li>
                  <li>Return to Ooty by evening</li>
                </ul>
              </div>
            </div>
            <div className="Plan-1">
              <h3 className="Plan_1_Title">Optional (Day 4-5)</h3>
              <video
                src="https://videos.pexels.com/video-files/6821235/6821235-uhd_2560_1440_30fps.mp4"
                className="visible-video"
                autoPlay
                loop
                muted
              ></video>
              <div className="Task_table">
                <ul>
                  <li>
                    Pykara Lake and Waterfalls (25 km, 1.5 hours):
                    <ul>
                      <li>Boat ride and fishing</li>
                      <li>Waterfalls hike</li>
                    </ul>
                  </li>
                  <li>
                    Mudumalai National Park (35 km, 2 hours):
                    <ul>
                      <li>Wildlife safari</li>
                      <li>Elephant camp visit</li>
                    </ul>
                  </li>
                  <li>
                    Avalanche Lake (28 km, 2 hours):
                    <ul>
                      <li>Trout fishing</li>
                      <li>Scenic views</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
