import React, { useEffect, useState } from "react";
import Footer from "../../page/Footer";
import Navbar from "../../page/Navbar";
import Subscription from "./Subscription";
import "./Partners.css";
import AWS from "aws-sdk";
import Mybookings from "../Login User/Mybookings";
import PostHotelData from "../../Partners components/PostHotelData";
import PostVehicleData from "../../Partners components/PostVehicleData";
import PostRestaurantData from "../../Partners components/PostRestaurantData";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ThreeDot } from "react-loading-indicators";
import { Helmet } from "react-helmet";
import PostBusinessIn from "../../Partners components/PostBusinessIn";
export default function Partners() {
  const [Stay, setStay] = useState(true);
  const [Restaurant, setRestaurant] = useState(false);
  const [Vehicle, setVehicle] = useState(false);
  const [Business, setBusiness] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const loginUserName = user?.signInDetails?.loginId;
  const [showSubscription, setShowSubscription] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSubscription(true);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  const [data, setData] = useState([]);

  // subscription Checker
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Subscription-User-list",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setData(result.Items);
      }
    });
  }, []);

  // Add ! below
  if (!loginUserName) {
    return;
  }

  const loginUserAsset = data?.filter((item) => item?.email === loginUserName);

  const today = new Date().toISOString().split("T")[0];
  const SubStatus = data?.filter((item) => {
    if (loginUserAsset.length > 0) {
      const endDate = new Date(loginUserAsset[0]?.end_date)
        .toISOString()
        .split("T")[0];
      return endDate <= today;
    }
    return false;
  });

  const SubPaidUser = loginUserAsset?.length > 0 && SubStatus.length === 0;

  return (
    <>
      <Helmet>
        <title>OotyGo Partners</title>
        <meta
          name="description"
          content="Direct Hotel Booking In Ooty | Best Booking Service In Ooty | Free Hotel Booking In Ooty"
        />
        <meta
          name="keywords"
          content="ooty hotel booking, ooty, ooty trip plan, best place in ooty, ooty visit, ooty site seeing, ooty resort, ooty cottage"
        />
        <meta
          name="google-adsense-account"
          content="ca-pub-6710626702056381"
        ></meta>
      </Helmet>

      <Navbar />
      <div className="Partners_section">
        <h2 className="Partner_titel">
          <span>
            {!SubPaidUser ? null : (
              <>
                <span>
                  Plan expiration date:{" "}
                  {loginUserAsset[0]?.end_date
                    .slice(0, 10)
                    .split("-")
                    .reverse()
                    .join("-")}
                </span>{" "}
                <span>Limit: {loginUserAsset[0]?.prop_count}</span>
              </>
            )}
          </span>
        </h2>
        <div className="Partner_Signup">
          {SubPaidUser ? (
            <>
              <Mybookings /> <br />
              <div className="Partner_Upload_selector">
                <button
                  onClick={() => {
                    setStay(true);
                    setRestaurant(false);
                    setVehicle(false);
                    setBusiness(false);
                  }}
                  className={Stay ? "Selected" : "NotSelected"}
                >
                  Stay
                </button>
                <button
                  onClick={() => {
                    setRestaurant(true);
                    setStay(false);
                    setVehicle(false);
                    setBusiness(false);
                  }}
                  className={Restaurant ? "Selected" : "NotSelected"}
                >
                  Food
                </button>
                <button
                  onClick={() => {
                    setVehicle(true);
                    setStay(false);
                    setRestaurant(false);
                    setBusiness(false);
                  }}
                  className={Vehicle ? "Selected" : "NotSelected"}
                >
                  Travels
                </button>
                <button
                  onClick={() => {
                    setBusiness(true);
                    setVehicle(false);
                    setStay(false);
                    setRestaurant(false);
                  }}
                  className={Business ? "Selected" : "NotSelected"}
                >
                  Business
                </button>
              </div>
              <div className="Partner_Upload_sec">
                {Stay ? <PostHotelData /> : null}
                {Restaurant ? <PostRestaurantData /> : null}
                {Vehicle ? <PostVehicleData /> : null}
                {Business ? <PostBusinessIn /> : null}
              </div>
            </>
          ) : (
            <div>
              {showSubscription && <Subscription />}
              {!showSubscription ? (
                <div className="Hotel_Loading_Ani">
                  <ThreeDot
                    color="#32cd32"
                    size="medium"
                    text=""
                    textColor=""
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
