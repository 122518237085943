import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { useState } from "react";
import Home from "./components/Home";
import Places from "./components/Places";
import Stay from "./components/Stay";
import Travel from "./components/Travel";
import Food from "./components/Food";
import SignUp from "./components/SignUp";
import ImageSlider from "./components/sub components/ImageSlider";
import SimsPark from "./components/sub components/SimsPark";
import DolphinNose from "./components/sub components/DolphinNose";
import KodanadView from "./components/sub components/KodanadView";
import Dottabetta from "./components/sub components/Dottabetta";
import BotanicalGarden from "./components/sub components/BotanicalGarden";
import About from "./components/About";
import OotyBoatHouse from "./components/sub components/OotyBoatHouse";
import KateriPark from "./components/sub components/KateriPark";
import Partners from "./components/sub components/Prtners";
import Terms from "./components/sub components/Terms";
import Support from "./components/sub components/Support";
import Posto from "./components/Posto";
import Plan from "./components/Plan";
import { useEffect } from "react";
import MyItems from "./components/Login User/MyItems";
import Pakage from "./components/Pakage";
import Business from "./components/Business";
import { FaWhatsapp } from "react-icons/fa";
import HotelDetail from "./components/sub components/HotelDetail";
import HotelList from "./components/sub components/HotelList";
import AdminUser from "./components/Login User/AdminUser";
import BusinessIn from "./components/BusinessIn";
import BusinessDetail from "./components/sub components/BusinessDetail";
import BusinessList from "./components/sub components/BusinessList";
const App = () => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const whatsappchat = `https://wa.me/919655062118?text=${encodeURIComponent(
    "Hi! OotyGo"
  )}`;

  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 1780) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <Authenticator.Provider>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/Places" element={<Places />} />
            <Route path="/Stay" element={<Stay />} />
            <Route path="/Travel" element={<Travel />} />
            <Route path="/Food" element={<Food />} />
            <Route path="/BusinessInOoty" element={<BusinessIn />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/SimsPark" element={<SimsPark />} />
            <Route path="/DolphinNose" element={<DolphinNose />} />
            <Route path="/ImageSlider" element={<ImageSlider />} />
            <Route path="/KodanadView" element={<KodanadView />} />
            <Route path="/Dottabetta" element={<Dottabetta />} />
            <Route path="/BotanicalGarden" element={<BotanicalGarden />} />
            <Route path="/About" element={<About />} />
            <Route path="/OotyBoatHouse" element={<OotyBoatHouse />} />
            <Route path="/KateriPark" element={<KateriPark />} />
            <Route path="/Partners" element={<Partners />} />
            <Route path="/Terms" element={<Terms />} />
            <Route path="/Support" element={<Support />} />
            <Route path="/Posto" element={<Posto />} />
            <Route path="/Plan" element={<Plan />} />
            <Route path="/MyItems" element={<MyItems />} />
            <Route path="/Package" element={<Pakage />} />
            <Route path="/Business" element={<Business />} />
            <Route path="/HotelList" element={<HotelList />} />
            <Route path="/hotel/:hotelId" element={<HotelDetail />} />
            <Route path="/BusinessList" element={<BusinessList />} />
            <Route path="/Business/:hotelId" element={<BusinessDetail />} />
            <Route path="/Adminuser" element={<AdminUser />} />
          </Routes>
        </Router>
        <div className="App_Chat_Btn">
          <span>
            <a href={whatsappchat}>
              <div className={isScrolled ? "Chat_Animation" : ""}></div>
              <button
                className={isScrolled ? "App_Chat_Btn_View" : "App_Chat_Btn_no"}
              >
                <FaWhatsapp />
              </button>
            </a>
          </span>
        </div>
      </Authenticator.Provider>
    </div>
  );
};

export default App;
