import Navbar from "../page/Navbar";
import Footer from "../page/Footer";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Helmet } from "react-helmet";
import { ThreeDot } from "react-loading-indicators";
import "./Pakage.css";
import { useNavigate } from "react-router-dom";
// import { FaSliders } from "react-icons/fa6";
// import { IoSearch } from "react-icons/io5";
import { FaSearchengin } from "react-icons/fa6";
import { MdAddCall } from "react-icons/md";
export default function Package() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const [packageData, setPackageData] = useState([]);
  const { user } = useAuthenticator((context) => [context.user]);
  const LoginUser = user?.signInDetails?.loginId;
  const [paid, setPaid] = useState(false);
  const [notPaid, setNotPaid] = useState(false);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-pakage",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setPackageData(result.Items);
      }
    });
  }, []);

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.head.appendChild(script);
    });
  };

  const handlePayment = async (name, Payment) => {
    const scriptLoaded = await loadRazorpayScript();
    const RzpAK = process.env.REACT_APP_RAZORPAY_API_KEY;
    if (!scriptLoaded) {
      console.error("Razorpay SDK failed to load.");
      return;
    }

    if (typeof window.Razorpay === "undefined") {
      console.error("Razorpay is not defined.");
      return;
    }

    const options = {
      key: RzpAK,
      amount: Payment * 100, // Amount in paise
      currency: "INR",
      name: LoginUser,
      description: "OotyGo - Tour Package",
      handler: function (response, err) {
        if (err) {
          setNotPaid(true);
          setTimeout(() => {
            setNotPaid(false);
          }, 5000);
        }
        console.log(response);
        storePaymentDetails(response, name, Payment);
        setPaid(true);
        setTimeout(() => {
          setPaid(false);
        }, 5000);
      },
      prefill: { name: "Name", email: LoginUser },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const storePaymentDetails = (response, name, Payment) => {
    const dynamoDb = new AWS.DynamoDB.DocumentClient();

    const params = {
      TableName: "ootygo-tour-payments",
      Item: {
        payment_id: response.razorpay_payment_id,
        username: LoginUser,
        package_name: name,
        amount: Payment,
        status: "Paid",
        date: new Date().toISOString(),
      },
    };

    dynamoDb.put(params, (err, data) => {
      if (err) {
        console.error(
          "Unable to add payment in DynamoDb",
          JSON.stringify(err, null, 2)
        );
      } else {
        console.log("Added payment:", JSON.stringify(data, null, 2));
      }
    });
  };

  // ! User Navigation
  const navigateSU = useNavigate();

  const handleNavSignup = () => {
    navigateSU("/SignUp");
  };
  // Filter Functions
  const [price, setPrice] = useState(500);
  // const [rating, setRating] = useState(0);
  const [cab, setCab] = useState(4);
  const [location, setLocation] = useState("");
  const [hotel, setHotel] = useState([]);
  const [travel, setTravel] = useState([]);

  const handlePrice = (event) => {
    setPrice(Number(event.target.value));
  };

  const handleCab = (event) => {
    setCab(event.target.value);
  };

  const handleLocation = (event) => {
    setLocation(event.target.value);
  };
  const handleFilter = async (event) => {
    event.preventDefault();
    // Convert price to a number if it's not already
    const numericPrice = Number(price);
    // Validation
    if (numericPrice <= 500 && cab < 4) {
      window.alert("Please select appropriate values for price and cab.");
      return;
    }
    if (!numericPrice || !location.trim()) {
      window.alert("Price and location are required.");
      return;
    }
    const dynamoDB = new AWS.DynamoDB.DocumentClient();
    const paramsHotel = {
      TableName: "Ootygo-hotel",
      FilterExpression:
        "rate BETWEEN :min_rate AND :max_rate OR area = :area_value",
      ExpressionAttributeValues: {
        ":min_rate": parseFloat(price) - 500,
        ":max_rate": parseFloat(price),
        ":area_value": location.trim(),
      },
    };

    try {
      const dataHotel = await dynamoDB.scan(paramsHotel).promise();
      if (dataHotel.Items.length > 0) {
        setHotel(dataHotel.Items);
      } else {
        window.alert("No matching hotels found.");
      }
    } catch (error) {
      console.error(
        "Error fetching items from Ootygo-hotel:",
        paramsHotel,
        error
      );
      window.alert("Error fetching items. Please try again later.");
    }

    const paramsTravel = {
      TableName: "Ootygo-travel",
      FilterExpression: "sitting = :value",
      ExpressionAttributeValues: { ":value": String(cab) },
    };
    try {
      const dataTravel = await dynamoDB.scan(paramsTravel).promise();
      setTravel(dataTravel.Items);
    } catch (error) {
      console.error(
        "Error fetching items from Ootygo-travel:",
        paramsTravel,
        error
      );
      window.alert("Error fetching items. Please try again later.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Ooty best tour package</title>
        <meta
          name="description"
          content="Ooty trip lan | Ooty plan | Top place to visit in Ooty | Ooty trip | Best place in Ooty | Exploe Ooty | Hidden place in ooty | Ooty Timing | Place to visit in Ooty"
        />
        <meta
          name="keywords"
          content="rental car, ooty, ooty trip plan, best place in ooty, ooty visit, ooty site seeing, ooty resort, ooty cottage"
        />
        <meta
          name="google-adsense-account"
          content="ca-pub-6710626702056381"
        ></meta>
      </Helmet>
      <Navbar />
      <div className="Package_Content">
        <h1 className="Package_contant_title">Best Ooty Package</h1>

        <div>
          <div className="Package_Filter_Section">
            <form
              action="submit"
              onSubmit={handleFilter}
              className="Filter_Form"
            >
              <div>
                <span className="Beta_Version">Beta</span>
                <label>
                  Price:{" "}
                  <span className="Filter_Price">
                    <b>{price}</b>
                  </span>
                </label>
                <input
                  type="range"
                  min="500"
                  max="25000"
                  step="100"
                  onChange={handlePrice}
                  value={price}
                  className="Filter_Input"
                />
              </div>
              <div>
                <label>Cab size:</label>{" "}
                <select
                  name="cab"
                  className="Filter_Select"
                  onChange={handleCab}
                >
                  <option value="4">4</option>
                  <option value="7">7</option>
                  <option value="9">9</option>
                  <option value="12">12</option>
                  <option value="16">16</option>
                  <option value="18">18</option>
                  <option value="20">20</option>
                  <option value="22">22</option>
                  <option value="26">26</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                  <option value="60">60</option>
                </select>
              </div>
              <div>
                <label>location: </label>
                <select
                  name=""
                  id=""
                  className="Filter_Select"
                  onChange={handleLocation}
                  type="text"
                >
                  <option value="Select">Select</option>
                  <option value="Ooty">Ooty</option>
                  <option value="Coonoor">Coonoor</option>
                  <option value="ooty">Kotagiri</option>
                  <option value="ooty">Gudalur</option>
                  <option value="ooty">Ooty Outer</option>
                  <option value="ooty">Coonoor Outer</option>
                  <option value="ooty">Mudumalai</option>
                  <option value="ooty">Forest Area</option>
                  <option value="ooty">Hidden Locations</option>
                  <option value="ooty">Best View</option>
                </select>
              </div>
              <div>
                <label>Days: </label>
                <select name="" id="" className="Filter_Select">
                  <option value="0">Select</option>
                  <option value="ooty">1</option>
                  <option value="ooty">2</option>
                  <option value="ooty">3</option>
                  <option value="ooty">4</option>
                </select>
              </div>
              <div>
                <button className="Package_Go_Btn" type="submit">
                  <FaSearchengin />
                </button>
              </div>
            </form>
          </div>
          <div className="Filter_Results">
            {hotel.length > 0 ? (
              <div>
                <h2 className="Filter_Result_Title">Options in hotels</h2>
                {hotel.map(({ name, rate, imgurl, cell }, index) => (
                  <div className="Filter_Result">
                    <div key={index} className="Filter_Result_item">
                      <img src={imgurl} alt="" className="Filter_Img" />
                      <h4>{name}</h4>
                      <span>Price/day: ₹ {rate}</span>
                      <span
                        onClick={() => (window.location.href = `tel:${cell}`)}
                        className="Filter_Call"
                      >
                        <MdAddCall />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {travel.length > 0 ? (
              <div>
                <h2 className="Filter_Result_Title">Options in travels</h2>
                {travel.map(({ name, pkg, sitting, imgurl, cell }, index) => (
                  <div className="Filter_Result">
                    <div key={index} className="Filter_Result_item">
                      <img src={imgurl} alt="" className="Filter_Img" />
                      <h4>{name}</h4>
                      <span>Price/day: ₹ {pkg} </span>
                      <br />
                      <span> Seating: {sitting}</span>
                      <span
                        onClick={() => (window.location.href = `tel:${cell}`)}
                        className="Filter_Call"
                      >
                        <MdAddCall />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`Pakage_pay_Status ${paid ? "paid" : "failed"} ${
            notPaid ? "notPaid" : "failed"
          }`}
        >
          <div className={paid ? "Paid_True" : "PaidFalse"}>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 52 52"
              xmlSpace="preserve"
              fill="#000000"
              height="50"
              width="50px"
            >
              <circle
                className="circle"
                fill="#4CAF50"
                cx="26"
                cy="26"
                r="25"
              />
              <path
                className="check"
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="5"
                strokeMiterlimit="10"
                d="M14,27l7.8,7.8l16.7-16.7"
              />
              <style>
                {`
          .circle {
            animation: circle-animation 0.6s ease-out forwards;
          }
          .check {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: check-animation 0.6s ease-out forwards 0.6s;
          }
          @keyframes circle-animation {
            0% {
              stroke-dasharray: 0, 150;
              stroke-dashoffset: 0;
              opacity: 1;
            }
            100% {
              stroke-dasharray: 150, 150;
              stroke-dashoffset: -100;
              opacity: 1;
            }
          }
          @keyframes check-animation {
            to {
              stroke-dashoffset: 0;
            }
          }
        `}
              </style>
            </svg>

            <h4>Payment Success</h4>
          </div>
          <div className={notPaid ? "NotPaid_True" : "NotPaidFalse"}>
            <svg
              width="100"
              height="100"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"
                fill="#f44336"
              />
            </svg>
            <h4>Payment Failed</h4>
          </div>
        </div>
        <div className="Package_Select">
          <div className="Package_TopList">
            {packageData.length > 0 ? (
              packageData
                .sort((a, b) => a.id - b.id)
                .map(
                  (
                    {
                      cell,
                      name,
                      Duration,
                      Exclusions,
                      Highlights,
                      Highlights_2,
                      ImgUrl,
                      Inclusions,
                      persons,
                      Payment,
                    },
                    index
                  ) => (
                    <div className="Package_TopList_Items" key={index}>
                      <h2>{name}</h2>
                      <p>
                        <b>Persons:</b> {persons === 0 ? "Custom" : persons}
                      </p>
                      <p>
                        <strong>Day 1: </strong>
                        {Highlights}
                      </p>
                      {Highlights_2 ? (
                        <p>
                          <strong>Day 2: </strong>
                          {Highlights_2}
                        </p>
                      ) : null}
                      <p>
                        <strong>Duration:</strong> {Duration}
                      </p>
                      <p>
                        <strong>Price:</strong> ₹
                        {Payment === 0 ? "Plan Basis" : Payment}
                      </p>
                      <p>
                        <strong>Exclusions: </strong>
                        {Exclusions}
                      </p>
                      <p>
                        <strong>Inclusions: </strong>
                        {Inclusions}
                      </p>
                      <button
                        onClick={
                          Payment === 0
                            ? () => {
                                if (!user) {
                                  handleNavSignup();
                                } else {
                                  window.location.href = `tel:${cell}`;
                                }
                              }
                            : () => {
                                if (!user) {
                                  handleNavSignup();
                                } else {
                                  handlePayment(name, Payment);
                                }
                              }
                        }
                        className="Pakage_pay_Btn"
                      >
                        {Payment === 0 ? "Call Now" : "Book Now"}
                      </button>
                    </div>
                  )
                )
            ) : (
              <div className="Places_contant_item_empty">
                <ThreeDot color="#32cd32" size="medium" text="" textColor="" />
              </div>
            )}
          </div>
          <h5 className="Payment_Email">
            <img
              src="https://pinlearn.com/wp-content/uploads/2021/10/razorpay-who.png"
              alt="payment options"
            />
            After payment, our dedicated travel assistant will contact you
            within 24 hours for assistance.
          </h5>
        </div>
      </div>
      <Footer />
    </>
  );
}
