import React, { useState } from "react";
import Navbar from "../page/Navbar";
import Footer from "../page/Footer";
import "./Places.css";
import { Link } from "react-router-dom";
import "../components/sub components/ImageSlider";
import "../components/sub components/SimsPark";
import "../components/sub components/KodanadView";
import "../components/sub components/Dottabetta";
import "../components/sub components/OotyBoatHouse";
import "../components/sub components/KateriPark";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import AWS from "aws-sdk";
import { ThreeDot } from "react-loading-indicators";
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});
export default function Places() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [data, setData] = useState([]);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-places",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setData(result.Items);
      }
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Top Place To Visite In Ooty</title>
        <meta
          name="description"
          content="Ooty's Beautiful Place| Best Place In Ooty | Ooty Trip Plan | Top 10 Place In Ooty"
        />
        <meta
          name="keywords"
          content="ooty hotel booking, ooty, ooty trip plan, best place in ooty, ooty visit, ooty site seeing, ooty nature, best blace tamilnadu "
        />
        <meta name="google-adsense-account" content="ca-pub-6710626702056381"></meta>
      </Helmet>
      <div>
        <Navbar />
      </div>
      <div className="Places_contant">
        <h1 className="Places_contant_titel">Top 10 Places In Ooty</h1>
        <div className="Places_contant_item">
          {data.length > 0 ? (
            data
              .sort((a, b) => a.lineno - b.lineno)
              .map(({ name, imgurl, lineno, pagelink }, index) => (
                <div key={index}>
                  <div className="Place_contant_place">
                    <Link to={pagelink}>
                      <img
                        className="Place_contant_img"
                        src={imgurl}
                        alt="Top Place"
                      />
                    </Link>
                    <span className="Places_contant_counts">{lineno}</span>
                  </div>
                  <h3 className="Places_contant_sub">{name}</h3>
                </div>
              ))
          ) : (
            <div className="Places_contant_item_empty">
              <ThreeDot color="#32cd32" size="medium" text="" textColor="" />
            </div>
          )}
          <div>
            <h4 className="Places_contant_sub">
              More places are arriving soon.
            </h4>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}
