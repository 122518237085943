import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../page/Navbar";
import AWS from "aws-sdk";
import { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { SlCallOut } from "react-icons/sl";
import { FaStar } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa6";
import { ImCancelCircle } from "react-icons/im";
import { CiCircleInfo } from "react-icons/ci";
import { BiSolidMessageAdd } from "react-icons/bi";
import { RiSendPlane2Line } from "react-icons/ri";
import { SlCalender } from "react-icons/sl";
import { FaLocationDot } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import logo from "../../logo.png";
import "./HotelDetail.css";
import DatePicker from "react-datepicker";
import { FaSquare } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { ThreeDot } from "react-loading-indicators";
import { Link } from "react-router-dom";
//Sliders
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FileSendOk from "../../assets/images/filesend.mp4";
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const HotelDetail = () => {
  let { hotelId } = useParams();

  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/SignUp");
  };
  // Getting Reviesw

  const [reviews, setReviews] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-hotel-reviews",
    };
    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setReviews(result.Items);
      }
    });
    // Reload the reviews

    if (isSubmitted) {
      dynamodb.scan(params, (err, result) => {
        if (err) {
          console.error("Error fetching data from DynamoDB:", err);
        } else {
          setReviews(result.Items);
        }
      });

      setIsSubmitted(false);
    }
  }, [isSubmitted]);
  const [postReview, setPostReview] = useState(false);

  // const [rating, setRating] = useState(0);

  // Post Review

  const docClient = new AWS.DynamoDB.DocumentClient();
  const initialFormData = {
    id: parseInt(hotelId, 10),
    name: "",
    rating: "1",
    review: "",
    Date: "",
    username: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const cDate = new Date();
    const year = cDate.getFullYear();
    const month = cDate.getMonth() + 1; // JavaScript months are 0-11
    const day = cDate.getDate();

    setFormData({
      ...formData,
      [name]: value,
      Date: `${year}-${month}-${day}`,
      username: user?.signInDetails?.loginId,
    });
  };

  const [sending, setSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setSending(true);
      const params = {
        TableName: "ootygo-hotel-reviews",
        Item: {
          ...formData,
        },
      };

      await docClient.put(params).promise();
      setTimeout(() => {
        setSending(false);
        setIsSubmitted(true);
      }, 2700);
      setFormData(initialFormData);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // Date available
  const [dates, setDates] = useState([]);
  const [datesShow, setDatesShow] = useState(false);
  const [hotelIdMatch, setHotelIdMatch] = useState(null);

  useEffect(() => {
    const dynamoDB = new AWS.DynamoDB.DocumentClient();

    const fetchDates = async () => {
      const params = { TableName: "ootygo-hotelavailability" };

      try {
        const data = await dynamoDB.scan(params).promise();
        const filteredItems = data?.Items.filter(
          (item) => item.hotelID === hotelIdMatch
        );

        if (filteredItems && filteredItems.length > 0) {
          const parsedDates = filteredItems.flatMap(
            (item) =>
              item.availableDate
                .split(",")
                .map((dateString) => {
                  const date = new Date(dateString.trim());
                  if (!isNaN(date)) {
                    return date;
                  } else {
                    console.warn(`Invalid date: ${dateString}`); // Log invalid dates
                    return null;
                  }
                })
                .filter((date) => date !== null) // Filter out null values
          );
          setDates(parsedDates.length > 0 ? parsedDates : []);
        } else {
          setDates([]);
        }
      } catch (error) {
        setDates([]);
        console.error("Error fetching data: ", error);
      }
    };
    if (hotelIdMatch) {
      fetchDates();
    }
  }, [hotelIdMatch]);

  const [message, setMessage] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage(
        <div>
          <p>Oops! Hotel not found</p>
          <button className="Hotel_Not_Found_Btn">
            <Link to="/Stay" className="Hotel_Not_Found">
              See Other Hotel
            </Link>
          </button>
        </div>
      );
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  // call btn clicks posting

  const handleCallClick = async (id, name) => {
    const dynamoDBC = new AWS.DynamoDB.DocumentClient();
    const getParams = {
      TableName: "Ootygo-hotel",
      Key: { id: id, name: name },
    };
    try {
      const data = await dynamoDBC.get(getParams).promise();
      const currentClicks = data?.Item.Clicks;
      const NewClicks = currentClicks + 1;
      const updateParams = {
        TableName: "Ootygo-hotel",
        Key: { id: id, name: name },
        UpdateExpression: "set Clicks = :newClick",
        ExpressionAttributeValues: { ":newClick": NewClicks },
        ReturnValues: "UPDATED_NEW",
      };
      await dynamoDBC.update(updateParams).promise();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  //   get hotel

  const [data, setData] = useState([]);

  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "Ootygo-hotel",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        setData(result.Items.filter((item) => item.id === Number(hotelId)));
      }
    });
  }, [hotelId]);

  return (
    <>
      <Helmet>
        <title>{data[0]?.name}</title>
        <meta name="description" content={data[0]?.about} />
        <link rel="icon" href={logo} />
        <meta
          name="keywords"
          content="ooty hotel booking, ooty, ooty trip plan, best place in ooty, ooty visit, ooty site seeing, ooty resort, ooty cottage"
        />
        <meta
          name="google-adsense-account"
          content="ca-pub-6710626702056381"
        ></meta>
      </Helmet>
      <div>
        <Navbar />
        {datesShow ? (
          <div className="hotel_date_card">
            <div>
              <h2>
                <FaSquare className="Hotel_booked" />
                Booked <FaSquare className="Hotel_free" />
                Free
                <span
                  onClick={() => {
                    setDatesShow(false);
                  }}
                  className="hotel_date_card_cancel"
                >
                  <MdOutlineCancel />
                </span>
              </h2>

              <span>
                <CiCircleInfo /> Availability may differ Verify with hotel
              </span>
              <DatePicker
                inline
                highlightDates={dates.length > 0 ? dates : []}
                readOnly
                minDate={new Date()} // Disables all dates before today
              />
            </div>
          </div>
        ) : null}

        <div className="Stay_contant_Hotels">
          {data?.length === 0 ? (
            <div className="Hotel_Loading_Ani">
              {(message !== "" && message) || (
                <ThreeDot color="#32cd32" size="medium" text="" textColor="" />
              )}
            </div>
          ) : (
            <div className="hotelcard">
              <div>
                <h1 className="Stay_Hotel_Display_Title">{data[0]?.name}</h1>
              </div>
              <div className="Stay_Hotel_Display">
                <div className="Stay_Hotel_Display_Imgs">
                  <img
                    src={data[0]?.imgurl}
                    alt="Hotel 1"
                    className="Stay_Hotel_Details_Img"
                  />{" "}
                  <img
                    src={data[0]?.imgurl2}
                    alt="Hotel 2"
                    className="Stay_Hotel_Details_Img"
                  />{" "}
                  <img
                    src={data[0]?.imgurl3}
                    alt="Hotel 3"
                    className="Stay_Hotel_Details_Img"
                  />{" "}
                  <video
                    src={data[0]?.video}
                    alt="Hotel 3"
                    className="Stay_Hotel_Details_Img"
                    controls
                    muted
                    controlsList="nodownload"
                  ></video>
                </div>
                <div>
                  <h3 className="Stay_Hotel_Display_Price">
                    Starting @ ₹ {data[0]?.rate}
                    {/* Date Available btn */}
                    <span className="Hotel_Calander">
                      <span
                        onClick={() => {
                          setDatesShow(true);
                          setHotelIdMatch(data[0]?.id);
                        }}
                      >
                        <SlCalender />
                      </span>
                      <a href={data[0]?.location}>
                        <FaLocationDot />
                      </a>
                    </span>
                  </h3>

                  <span className="Stay_Display_call">
                    {" "}
                    <b
                      onClick={
                        user
                          ? () => {
                              window.location.href = `tel:${data[0]?.cell}`;
                              handleCallClick(data[0]?.id, data[0]?.name);
                            }
                          : handleClick
                      }
                    >
                      <SlCallOut />
                    </b>
                  </span>
                  <div className="Stay_Hotel_Display_Ratings">
                    <span>
                      {reviews.length > 0 ? (
                        (() => {
                          const filteredReviews = reviews.filter(
                            ({ id }) => id === data[0]?.id
                          );
                          const maxRating =
                            filteredReviews.length > 0
                              ? Math.max(
                                  ...filteredReviews.map(({ rating }) => rating)
                                )
                              : null;
                          return maxRating !== null ? (
                            <div key={data[0]?.id}>
                              {Array.from({ length: maxRating }).map(
                                (_, index) => (
                                  <FaStar key={index} className="RatingStar" />
                                )
                              )}
                              <span className="RatingStar"> {maxRating} </span>
                            </div>
                          ) : (
                            <span className="RatingStar">
                              <FaRegStar />
                              <FaRegStar />
                              <FaRegStar />
                              <FaRegStar />
                              <FaRegStar />
                            </span>
                          );
                        })()
                      ) : (
                        <span className="RatingStar">
                          <FaRegStar />
                          <FaRegStar />
                          <FaRegStar />
                          <FaRegStar />
                          <FaRegStar />
                        </span>
                      )}
                    </span>
                  </div>
                  <div>
                    <p className="Stay_Hotel_Display_About">
                      <CiCircleInfo /> {data[0]?.about}
                    </p>
                  </div>
                  <div className="Stay_Hotel_Display_Reviews">
                    <hr />
                    <h4>Reviews</h4>
                    <span
                      className={
                        postReview ? "Stay_Display_call" : "Stay_Display_Post"
                      }
                    >
                      <span>
                        {postReview ? (
                          <BiSolidMessageAdd
                            onClick={() => {
                              setPostReview(false);
                            }}
                            className="Post_btns_msg"
                          />
                        ) : null}
                      </span>
                      <div>
                        <form onSubmit={user ? handleSubmit : handleClick}>
                          <ImCancelCircle
                            onClick={() => setPostReview(true)}
                            className="Post_btns"
                          />{" "}
                          <input
                            type="number"
                            value={data[0]?.id}
                            onChange={handleChange}
                            name="id"
                            disabled
                            className="Review_Hotel_Name"
                          />{" "}
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            required
                          />{" "}
                          <label htmlFor="customRange2" className="form-label">
                            {" "}
                            Rating: {formData.rating}{" "}
                            {formData.rating &&
                              Array.from({ length: formData.rating }).map(
                                (_, index) => (
                                  <FaStar key={index} className="RatingStar" />
                                )
                              )}
                          </label>{" "}
                          <input
                            type="range"
                            className="form-range"
                            min="0"
                            max="5"
                            id="customRange2"
                            name="rating"
                            value={formData.rating}
                            onChange={handleChange}
                            required
                          />
                          <textarea
                            name="review"
                            value={formData.review}
                            placeholder="Type something..."
                            onChange={handleChange}
                          ></textarea>{" "}
                          <br />{" "}
                          <button type="submit" className="Post_btns">
                            {sending ? (
                              <video
                                src={FileSendOk}
                                autoPlay
                                className="Post_Ok"
                              ></video>
                            ) : (
                              <RiSendPlane2Line className="Post_btns_post" />
                            )}
                          </button>
                        </form>
                      </div>
                    </span>
                    <div>
                      <div>
                        {reviews.length > 0 ? (
                          reviews.map(({ id, name, rating, review }, index) =>
                            data[0]?.id === id ? (
                              <div
                                key={index}
                                className="Stay_Hotel_Display_Review"
                              >
                                <h4>{name}</h4>
                                <div>
                                  <span>
                                    {Array.from({ length: rating }).map(
                                      (_, index) => (
                                        <FaStar
                                          key={index}
                                          className="RatingStar"
                                        />
                                      )
                                    )}
                                  </span>

                                  <span>{rating}</span>
                                </div>
                                <p>{review}</p>
                              </div>
                            ) : null
                          )
                        ) : (
                          <span>Add 1st review</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HotelDetail;
