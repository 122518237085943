// import React, { useState, useEffect} from 'react';
import "./Time.css";
import pkgs from "../../src/assets/Place.png";
import rest from "../../src/assets/3942052.png";
import food from "../../src/assets/restaurant_562678.png";
import travel from "../../src/assets/destination_854996.png";
import { Link } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
const Time = () => {

  return (
    <>
      <div className="Timbar_Navigations">
        <div className="Timbar_Navigation">
          <Link to="/Plan">
            <img src={pkgs} alt="" />
            <p>Planer</p>
          </Link>
        </div>
        <div className="Timbar_Navigation">
          <Link to="/Stay">
            <img src={rest} alt="" />
            <p>Resorts</p>
          </Link>
        </div>
        <div className="Timbar_Navigation">
          <Link to="/Food">
            <img src={food} alt="" />
            <p>Food</p>
          </Link>
        </div>
        <div className="Timbar_Navigation">
          <Link to="/Travel">
            <img src={travel} alt="" />
            <p>Travel</p>
          </Link>
        </div>
        <div className="Timbar_Navigation">
          <Link to="/Places">
            <IoLocationOutline />
            <p>Place</p>
          </Link>
        </div>
        
      </div>
    </>
  );
};

export default Time;
