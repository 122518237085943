import React, { useEffect } from "react";
import { Authenticator, CheckboxField } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import "./SignUp.css";
import awsExports from "../aws-exports";
import Navbar from "../page/Navbar";
import Footer from "../page/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuthenticator } from "@aws-amplify/ui-react";
Amplify.configure(awsExports);

export default function SignUp() {
  const { user } = useAuthenticator((context) => [context.user]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/Partners");
  };
  const handleClickSup = () => {
    navigate("/Support");
  };
  const handleClickMyItems = () => {
    navigate("/MyItems");
  };
  const handleClickTerms = () => {
    navigate("/Terms");
  };
  const components = {
    SignUp: {
      FormFields() {
        return (
          <>
            <Helmet>
              <title>{user ? <>Ootygo</> : <>Ootygo Login</>}</title>
              <meta
                name="description"
                content="Ooty trip lan | Ooty plan | Top place to visit in Ooty | Ooty trip | Best place in Ooty | Exploe Ooty | Hidden place in ooty | Ooty Timing | Place to visit in Ooty"
              />
              <meta
                name="keywords"
                content="rental car, ooty, ooty trip plan, best place in ooty, ooty visit, ooty site seeing, ooty resort, ooty cottage"
              />
            </Helmet>

            <Authenticator.SignUp.FormFields />
            <div>
              <span className="Terms_Link">
                <CheckboxField
                  label="I agree to the following"
                  name="terms"
                  value="yes"
                  required
                />
              </span>
              <span className="Terms_Link">
                <Link to="/Terms">SLA, Terms & Conditions, Privacy Policy</Link>
              </span>
            </div>
          </>
        );
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>OotyGo Login</title>
        <meta
          name="description"
          content="Login or SignUp into OotyGo | Book hotels, resort, cottage in Ooty | Travels in Ooty | Partner with OotyGO"
        />
        <meta
          name="keywords"
          content="ootygo login, ootygo signup, ootygo.in, ootygo.com, ootygo plan, ootygo, ootygo website, booking in ooty, it company in ooty"
        />
      </Helmet>
      <Navbar />
      <div className="Signup_contant">
        <h1 className="Travel_contant_title">My profile</h1>
        <div className="Signup_section">
          <Authenticator components={components}>
            {({ signOut, user }) => (
              <>
                <main>
                  <h1 className="Signup_User_greet">
                    Hello! 😊 <br />
                    {user.signInDetails.loginId
                      .split("")
                      .map((char, i) => (i % 2 === 0 ? char : "x"))
                      .join("")}
                  </h1>
                  <div className="User_Profile_Section">
                    <div className="User_Profile_Section_item">
                      <button
                        className="Signin_btns"
                        onClick={handleClickMyItems}
                      >
                        History
                      </button>
                      <button className="Signin_btns" onClick={handleClickSup}>
                        Support
                      </button>
                      <button className="Signin_btns" onClick={handleClick}>
                        My Properties
                      </button>
                      <button
                        className="Signin_btns"
                        onClick={handleClickTerms}
                      >
                        T&C
                      </button>
                      <button onClick={signOut} className="Signin_btns">
                        Sign out
                      </button>
                    </div>
                  </div>
                </main>
              </>
            )}
          </Authenticator>
        </div>
      </div>
      <Footer />
    </>
  );
}
