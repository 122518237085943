import React, { useState } from "react";
import Navbar from "../../page/Navbar";
import Footer from "../../page/Footer";
import "./MyItems.css";
import { useEffect } from "react";
import AWS from "aws-sdk";
import { SlCallOut } from "react-icons/sl";
import { CiBookmarkRemove } from "react-icons/ci";
import { ThreeDot } from "react-loading-indicators";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Helmet } from "react-helmet";
export default function MyItems() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { user } = useAuthenticator((context) => [context.user]);
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  useEffect(() => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-my-fav-items",
    };

    dynamodb.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        // Filter items based on loginId
        const userList = result.Items.filter(
          (item) => item.name === user?.signInDetails.loginId
        );
        setData(userList);
      }
    });
  }, [user?.signInDetails.loginId, data]);

  //   Delete Fav Function

  const handleFavDelete = async (id, name) => {
    const dynamoDBDLP = new AWS.DynamoDB.DocumentClient();
    const Params = {
      TableName: "ootygo-my-fav-items",
      Key: {
        id: id,
        name: name,
      },
    };
    try {
      const userConfirmed = window.confirm(`Remove ${id} from favorites?`);
      if (userConfirmed) {
        await dynamoDBDLP.delete(Params).promise();
      }
    } catch (error) {
      console.error("Error Deleting data:", error);
    }
  };

  const [message, setMessage] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("Empty 😕");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  // My Booking Functions

  useEffect(() => {
    const dynamodbMB = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "ootygo-tour-payments",
    };

    dynamodbMB.scan(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
      } else {
        // Filter items based on loginId
        const userList = result.Items.filter(
          (item) => item.username === user?.signInDetails.loginId
        );
        setBookingData(userList);
      }
    });
  }, [user?.signInDetails.loginId, bookingData]);

  return (
    <>
      <Helmet>
        <title>OotyGo my favorites</title>
        <meta
          name="description"
          content="Ooty trip lan | Ooty plan | Top place to visit in Ooty | Ooty trip | Best place in Ooty | Exploe Ooty | Hidden place in ooty | Ooty Timing | Place to visit in Ooty"
        />
        <meta
          name="keywords"
          content="rental car, ooty, ooty trip plan, best place in ooty, ooty visit, ooty site seeing, ooty resort, ooty cottage"
        />
      </Helmet>
      <Navbar />
      <div className="Myitem_Contant">
        <h1>My favorites</h1>
        <div className="Myitem_Items">
          <div>
            {data.length > 0 ? (
              <div className="Myitem_Item">
                {data.map(({ id, name, imgurl, rate, cell }, index) => (
                  <div key={index} className="Myitem_Item_list">
                    <div>
                      <h4 className="Myitem_Item_Titel">{id}</h4>
                      <img src={imgurl} alt="" className="Myitem_Item_img" />
                      <p className="Myitem_Item_Details">
                        <span>₹ {rate}</span>
                        <span>
                          <b
                            onClick={() => {
                              window.location.href = `tel:${cell}`;
                            }}
                          >
                            <SlCallOut />
                          </b>
                        </span>
                        <span onClick={() => handleFavDelete(id, name)}>
                          <CiBookmarkRemove />
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="Myitem_Item_Empty">
                <p>
                  {(message !== "" && message) || (
                    <ThreeDot
                      color="#32cd32"
                      size="medium"
                      text=""
                      textColor=""
                    />
                  )}
                </p>
              </div>
            )}
          </div>
          <div className="Myitems_Order">
            <h1>My payment history</h1>
            {bookingData.length > 0 ? (
              <div className="Myitem_Item">
                {bookingData.map(
                  (
                    { payment_id, amount, date, package_name, status },
                    index
                  ) => (
                    <div key={index} className="Myitem_Item_list">
                      <div>
                        <h4 className="Myitem_Item_Titel">{package_name}</h4>
                        <div className="Myitem_Bookings_Items">
                          <p>
                            <b>Amount:</b> ₹ {amount}
                          </p>
                          <p>Date: {date}</p>
                          <p>R.No: {payment_id}</p>
                          <p>Status: {status}</p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="Myitem_Item_Empty">
                <p>
                  {(message !== "" && message) || (
                    <ThreeDot
                      color="#32cd32"
                      size="medium"
                      text=""
                      textColor=""
                    />
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
